import { useState } from 'react';

export function useAnchor() {
  // eslint-disable-next-line no-bitwise,no-self-compare
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickToAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseToAnchor = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    setAnchorEl,
    handleClickToAnchor,
    handleCloseToAnchor
  };
}
