import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SendBillingContainer = styled(Box)({
  width: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  padding: '25px',
  display: 'flex',
  flexDirection: 'column'
});

export const BillingFormContainer = styled(Box)({
  borderRadius: '8px',
  margin: '8px'
});

export const HorizontalLine = styled('div')({
  width: '100%',
  height: '1px',
  backgroundColor: '#E77E22'
});

export const ButtonsContainer = styled(Box)({
  width: '90%',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  bottom: '0',
  marginBottom: '10px'
});
