import {
  MIN_CHARACTERS_REGEX,
  SPECIAL_CHARACTER_REGEX,
  LOWER_CASE_REGEX,
  UPPER_CASE_REGEX,
  NUMBER_REGEX,
  MAX_CHARACTERS_REGEX,
  SPACE_NOT_ALLOWED
} from './validate-password-security';

export const passwordRegexAndCriteria = [
  {
    regex: NUMBER_REGEX,
    criteria: 'Pelo menos um numeral (1 a 9)'
  },
  {
    regex: MIN_CHARACTERS_REGEX,
    criteria: 'Mínimo de 8 caracteres'
  },
  {
    regex: SPECIAL_CHARACTER_REGEX,
    criteria: 'Pelo menos 1 caractere especial (!@#$%&*)'
  },
  {
    regex: MAX_CHARACTERS_REGEX,
    criteria: 'Máximo 16 caracteres'
  },
  {
    regex: UPPER_CASE_REGEX,
    criteria: 'Pelo menos 1 letra maiúscula'
  },
  {
    regex: LOWER_CASE_REGEX,
    criteria: 'Pelo menos 1 letra minúscula'
  },
  {
    regex: SPACE_NOT_ALLOWED,
    criteria: 'Não deve conter espaços'
  }
];

// prettier-ignore
export const cpfRegex =
// eslint-disable-next-line
/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/gm;

// prettier-ignore
// eslint-disable-next-line
export const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$|^\d{14}$/;

// prettier-ignore
// eslint-disable-next-line
export const phoneRegex =
  /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[2-9]\d{3}[ .-]?\d{4}$/gm;

export const regexValidation = (regex, text) => new RegExp(regex).test(text);
