import * as api from './api';

export const getBilling = async (
  { client, clientName, startDate, endDate, isVisualized, billCode, buyOrder },
  page,
  pageSize
) => {
  try {
    let path = 'billing';
    if (client) {
      path = path.concat(`?client=${client}`);
    }
    if (billCode) {
      path = path.concat(path.includes('?') ? `&billCode=${billCode}` : `?billCode=${billCode}`);
    }
    if (buyOrder) {
      path = path.concat(path.includes('?') ? `&buyOrder=${buyOrder}` : `?buyOrder=${buyOrder}`);
    }
    if (clientName) {
      path = path.concat(
        path.includes('?') ? `&clientName=${clientName}` : `?clientName=${clientName}`
      );
    }
    if (startDate) {
      path = path.concat(
        path.includes('?') ? `&startDate=${startDate}` : `?startDate=${startDate}`
      );
    }
    if (endDate) {
      path = path.concat(path.includes('?') ? `&endDate=${endDate}` : `?endDate=${endDate}`);
    }

    if (isVisualized) {
      path = path.concat(
        path.includes('?') ? `&isVisualized=${isVisualized}` : `?isVisualized=${isVisualized}`
      );
    }

    if (page) {
      path = path.concat(path.includes('?') ? `&page=${page}` : `?page=${page}`);
    }
    if (pageSize) {
      path = path.concat(path.includes('?') ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`);
    }

    const { data, count } = await api.get(path);
    return { data, count };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getBill = async (id) => {
  try {
    const data = await api.get(`billing/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDoc = async (key) => {
  try {
    const { data, mimetype } = await api.get(`billing/download/${key.replaceAll('/', '%2F')}`);
    const file = new Blob([data], {
      type: mimetype
    });
    const fileURL = URL.createObjectURL(file);

    return { fileURL, pdf: mimetype.includes('pdf') };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteBilling = async (id) => {
  try {
    const data = await api.del(`billing/${id}`);

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendFiles = async (data, fileType, origin, ticketInfo) => {
  const form = new FormData();
  form.append('origin', origin);
  form.append('fileType', fileType);

  if (ticketInfo) {
    form.append('ticketInfo', JSON.stringify(ticketInfo));
  }

  // eslint-disable-next-line no-restricted-syntax, prefer-const
  for (let file of data) {
    form.append('files', file);
  }

  const doc = await api.postDocuments('billing/upload', form);

  return doc;
};

export const createBilling = async (
  client,
  billCode,
  bill,
  dueDate,
  billValue,
  trackingId,
  buyOrder
) => {
  const doc = await api.post('billing', {
    client,
    billCode,
    bill,
    dueDate,
    billValue,
    trackingId,
    buyOrder
  });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const update = async (client, bill, dueDate, trackingId, billCode, billValue, buyOrder) => {
  const doc = await api.patch(`billing/${client}`, {
    bill,
    dueDate,
    trackingId,
    billCode,
    billValue,
    buyOrder
  });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const consolidate = async (id, email) => {
  const doc = await api.post(`billing/consolidate`, { id, email });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const getTickets = async (clientDocument, problem) => {
  const doc = await api.post(`billing/traffic-tickets`, {
    clientDocument,
    problem
  });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const confirmTicket = async (billingId, billKey) => {
  const doc = await api.post(`billing/traffic-tickets/confirm/${billingId}`, {
    billKey
  });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const informDivergence = async (billingId, billKey, divergence, problem) => {
  const doc = await api.post(`billing/traffic-tickets/inform-divergence/${billingId}`, {
    billKey,
    divergence,
    problem
  });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const setIsVisualizedTrue = async (billingId, billKey) => {
  const doc = await api.post(`billing/traffic-tickets/visualized/${billingId}`, {
    billKey
  });

  if (doc.statusCode) {
    return null;
  }

  return doc;
};

export const getTrafficTickets = async (filter) => {
  const { data, count } = await api.post('billing/traffic-tickets', filter);
  return { data, count };
};

export const updateTicket = async (
  id,
  value,
  vehiclePlate,
  ait,
  ticketLevel,
  limitIdentificationDate
) => {
  try {
    const response = await api.post(`billing/traffic-tickets/update/${id}`, {
      value,
      vehiclePlate,
      ait,
      ticketLevel,
      limitIdentificationDate
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const softDelete = async (id) => {
  try {
    const response = await api.post(`billing/traffic-tickets/remove/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
