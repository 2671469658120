/* eslint-disable no-unused-vars */
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAuth } from '../../../../contexts';
import { recaptcha } from '../../../../services';

export default function LoginForm() {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('O e-mail deve ser um endereço de e-mail válido')
      .required('E-mail é obrigatório'),
    password: Yup.string().required('Senha é obrigatória')
  });

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (value) => {
      let isReCaptchaValid = false;
      try {
        const tokenRecaptcha = await executeRecaptcha('login');
        isReCaptchaValid = await recaptcha.verifyReCaptcha(tokenRecaptcha);
      } catch (error) {
        toast.error(error.message);
      }
      if (!isReCaptchaValid) return null;

      const result = await signIn(value);
      const state = location?.state;
      if (result) {
        return navigate('/user', { replace: true, state });
      }

      return setError('Usuário e/ou Senha inválido(s)');
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onVerify = useCallback((token) => {
    setToken(token);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} id="login-form">
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean((touched.email && errors.email) || error)}
            helperText={(touched.email && errors.email) || error}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean((touched.password && errors.password) || error)}
            helperText={(touched.password && errors.password) || error}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={2}>
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                checked={values.remember}
                style={{ color: 'darkblue' }}
              />
            }
            label="Lembrar"
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to="/auth/forgot"
            style={{ color: 'darkblue', textDecoration: 'none' }}
          >
            Esqueceu sua senha?
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          style={{ backgroundColor: '#1d038a', color: 'white' }}
        >
          Entrar
        </LoadingButton>

        <Stack alignItems="center" padding={2}>
          <Link
            rel="noopener  noreferrer"
            href="/sign-up"
            target="_blank"
            variant="subtitle2"
            style={{ color: 'darkblue', textDecoration: 'none' }}
          >
            Não possui cadastro? Clique aqui.
          </Link>
        </Stack>
        <Stack alignItems="center">
          <Typography color="#747474" fontSize=".7rem">
            Este site é protegido pelo reCAPTCHA e as{' '}
            <Link
              rel="noopener  noreferrer"
              href="https://policies.google.com/privacy"
              target="_blank"
              fontWeight="bold"
              color="#000"
            >
              Políticas de Privacidade
            </Link>{' '}
            e os{' '}
            <Link
              rel="noopener  noreferrer"
              href="https://policies.google.com/terms"
              target="_blank"
              fontWeight="bold"
              color="#000"
            >
              Termos de Serviço
            </Link>{' '}
            do Google se aplicam.
          </Typography>
        </Stack>

        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} action="login" />
      </Form>
    </FormikProvider>
  );
}
