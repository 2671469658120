import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import BillCodeFilter from './BillCodeFilter';
import BuyOrderFilter from './BuyOrderFilter';
import ClientFilter from './ClientFilter';
import ClientFilterMode from './ClientFilterMode';
import DateFilter from './DateFilter';
import VisualizedFilter from './VisualizedFilter';

const TableFilters = ({
  clients,
  clientFilterMode,
  setClientFilterMode,
  filters,
  setFilters,
  handleCleanFilters,
  handleClickFilter,
  handleReset
}) => {
  const handleChangeClientFilter = (event) => {
    if (clientFilterMode === 'name') {
      setFilters({ ...filters, clientName: event.target.value });
    } else {
      setFilters({ ...filters, client: event.target.value });
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginTop: 10
        }}
      >
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-around',
            marginBottom: '20px',
            gap: '2px !important'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-around',
              paddingTop: 10,
              paddingLeft: 10
            }}
          >
            <ClientFilterMode setClientFilterMode={setClientFilterMode} />
          </div>
          <ClientFilter
            clientFilterMode={clientFilterMode}
            handleChangeClientFilter={handleChangeClientFilter}
            filters={filters}
            clients={clients}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-around',
          marginBottom: '20px',
          gap: '2px !important'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <BuyOrderFilter filters={filters} setFilters={setFilters} />
        </div>

        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <BillCodeFilter filters={filters} setFilters={setFilters} />
        </div>

        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <DateFilter filters={filters} setFilters={setFilters} />
        </div>

        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <VisualizedFilter filters={filters} setFilters={setFilters} />
        </div>

        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={handleClickFilter} style={{ height: 55 }}>
            Pesquisar
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={handleCleanFilters} style={{ height: 55 }}>
            Limpar filtros
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={handleReset} style={{ height: 55 }}>
            Restaurar
          </Button>
        </div>
      </div>
    </>
  );
};

TableFilters.propTypes = {
  clients: PropTypes.array,
  clientFilterMode: PropTypes.string,
  setClientFilterMode: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  handleCleanFilters: PropTypes.func,
  handleClickFilter: PropTypes.func,
  handleReset: PropTypes.func
};

export default TableFilters;
