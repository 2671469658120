import * as manager from './api';

export const create = async (form) => {
  try {
    const data = await manager.post('publicUser', form);
    return data;
  } catch {
    return { statusCode: 500 };
  }
};
