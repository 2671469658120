import { CircularProgress } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const Background = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`
}));

export default function Loading() {
  return (
    <>
      <Background>
        <CircularProgress />
      </Background>
    </>
  );
}
