import { Home, ManageHistory, Settings } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PropTypes from 'prop-types';

import { useAuth } from '../../../contexts';
import NavSection from './NavSection';

export default function Menu({ onClick, handleClick }) {
  const { profile } = useAuth();
  const home = '/user';

  const menu = [
    {
      title: 'Inicio',
      icon: <Home />,
      department: ['general'],
      path: home,
      role: ['admin', 'seteloc', 'manager']
    },
    { title: 'Adm. Usuários', icon: <ManageAccountsIcon />, path: '/user', role: ['superadmin'] },
    {
      title: 'Adm. Seteloc',
      icon: <Settings />,
      role: ['admin', 'seteloc'],
      children: [
        {
          title: 'Faturas',
          role: ['admin', 'seteloc'],
          path: '/billing'
        },
        {
          title: 'Logs Internos',
          role: ['admin', 'seteloc'],
          path: '/notification'
        }
      ]
    },
    {
      title: 'Adm. Clientes',
      icon: <ManageHistory />,
      role: ['admin', 'seteloc'],
      path: '/clients'
    }
  ];

  function iam(acc, item, fn) {
    item.children = (item.children || []).reduce((a, i) => iam(a, i, fn), []);
    if (!item.children.length) delete item.children;
    if (fn(item) || item.children) acc.push(item);

    return acc;
  }

  const user = menu.reduce(
    (a, i) =>
      iam(
        a,
        i,
        (item) =>
          item?.role?.some((e) => profile?.role?.includes(e)) ||
          item?.department?.some((e) => e.includes(profile?.iam?.map((x) => x.department)))
      ),
    []
  );

  return <NavSection config={[...user]} onClick={onClick} handleClick={handleClick} />;
}

Menu.propTypes = {
  onClick: PropTypes.func,
  handleClick: PropTypes.bool
};
