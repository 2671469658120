import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAnchor } from '../../../../hooks/useAnchor';

// eslint-disable-next-line react/prop-types
export function TableOption({ options, row }) {
  const { anchorEl, handleClickToAnchor, handleCloseToAnchor } = useAnchor();

  return (
    <>
      <IconButton onClick={handleClickToAnchor}>
        <MoreVertIcon />
      </IconButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseToAnchor}>
        {/* eslint-disable-next-line react/prop-types */}
        {options.map((option, key) => option.render(row, key))}
      </Menu>
    </>
  );
}
