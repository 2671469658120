import { Typography, Chip } from '@mui/material';

export const columns = [
  {
    field: 'date',
    headerName: 'Inserido em',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false,
    type: 'date',
    renderCell: ({ row }) => (
      <Typography>
        {new Date(row.createdAt).toLocaleDateString('pt-BR', {
          timeZone: 'America/Sao_Paulo'
        })}
      </Typography>
    )
  },
  {
    field: 'protocol',
    headerName: 'Protocolo',
    minWidth: 150,
    flex: 1,
    editable: false
  },
  {
    field: 'rent',
    headerName: 'Doc de Locação',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => {
      const { bill } = row;

      return <Typography>{bill.filter((e) => e.origin === 'rent').length}</Typography>;
    }
  },
  {
    field: 'excess',
    headerName: 'Doc de KM Excedente',
    minWidth: 180,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => {
      const { bill } = row;

      return <Typography>{bill.filter((e) => e.origin === 'excess').length}</Typography>;
    }
  },
  {
    field: 'ticket',
    headerName: 'Doc de Multa',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => {
      const { bill } = row;

      return <Typography>{bill.filter((e) => e.origin === 'ticket').length}</Typography>;
    }
  },
  {
    field: 'refund',
    headerName: 'Doc de Reembolso',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => {
      const { bill } = row;

      return <Typography>{bill.filter((e) => e.origin === 'refund').length}</Typography>;
    }
  },
  {
    field: 'lastVisualized',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => (
      <Chip
        label={row.lastVisualized ? 'Visualizado' : 'Não visualizado'}
        variant="filled"
        color={row.lastVisualized ? 'default' : 'warning'}
      />
    )
  }
];
