import VMasker from 'vanilla-masker';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const columns = [
  {
    field: 'name',
    headerName: 'Nome',
    minWidth: 200,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: ({ row }) =>
      row && (
        <Box display="flex" alignItems="center">
          <Typography>
            {row?.firstName} {row?.lastName}
          </Typography>
          {row?.mainUser && (
            <Tooltip title="Esse usuário é principal">
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )
  },
  {
    field: 'email',
    headerName: 'E-mail',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false,
    valueGetter: ({ row }) => row?.phone && VMasker.toPattern(row?.phone, '(99) 9 9999-9999')
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: ({ row }) => (row.active ? 'Habilitado' : 'Desabilitado'),
    sortable: false
  },
  {
    field: 'area',
    headerName: 'Área',
    minWidth: 150,
    flex: 1,
    editable: false,
    sortable: false
  }
];
