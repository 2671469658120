import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const TypographyStyled = styled(Typography)`
  display: flex;
  padding: 10px;
  align-items: center;
  border: 1px solid #98a2b3;
  color: #667085;
  background-color: #eaecf0;
  border-radius: 8px;
  width: 350px;
  height: 50px;
`;
