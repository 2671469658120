export const billingOptions = [];

billingOptions.ticket = [
  {
    key: 'Boleto',
    value: 'billet'
  },
  {
    key: 'Deposito',
    value: 'deposit'
  },
  {
    key: 'Infração',
    value: 'infringement'
  },
  {
    key: 'NIC',
    value: 'nic'
  }
];

billingOptions.rent = [
  {
    key: 'Boleto',
    value: 'billet'
  },
  {
    key: 'Deposito',
    value: 'deposit'
  },
  {
    key: 'Demonstrativo',
    value: 'demonstrative'
  },
  {
    key: 'Fatura de Locação',
    value: 'rentInvoice'
  },
  {
    key: 'Fatura',
    value: 'invoice'
  },
  {
    key: 'Excel do Demonstrativo',
    value: 'excelDemonstrative'
  }
];

billingOptions.debitNote = [
  {
    key: 'Pro ratas',
    value: 'proRatas'
  },
  {
    key: 'Nota de Débito',
    value: 'debitNote'
  }
];

billingOptions.excess = [
  {
    key: 'Fatura',
    value: 'invoice'
  },
  {
    key: 'Memória de Cálculo',
    value: 'calculationMemory'
  }
];

billingOptions.other = [
  {
    key: 'Fatura',
    value: 'invoice'
  },
  {
    key: 'Outros',
    value: 'other'
  }
];

export const ticketLevelOptions = [
  {
    key: 'Leve',
    value: 'light'
  },
  {
    key: 'Média',
    value: 'medium'
  },
  {
    key: 'Grave',
    value: 'heavy'
  },
  {
    key: 'Gravíssima',
    value: 'severe'
  }
];
