import { Box, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as S from '../../styles';

export default function HeaderCompanyDetails({ client }) {
  return (
    <Paper elevation={1} sx={{ m: 1, p: 2 }}>
      <Box
        style={{
          width: '100%',
          display: 'block',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            marginTop: '1rem',
            fontSize: '30px'
          }}
        >
          Dados da Empresa
        </Typography>
        <hr
          style={{
            border: 0,
            borderTop: '2px solid #E77E22',
            marginTop: '1.3rem'
          }}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginBottom: '1rem',
            marginTop: '2.5rem'
          }}
        >
          <Box>
            <Typography sx={{ color: '#D05303' }} variant="subtitle2">
              Cliente
            </Typography>
            <S.TypographyStyled>{client?.name}</S.TypographyStyled>
          </Box>
          <Box>
            <Typography sx={{ color: '#D05303' }} variant="subtitle2">
              CNPJ/CPF
            </Typography>
            <S.TypographyStyled>{client?.document}</S.TypographyStyled>
          </Box>
          <Box>
            <Typography sx={{ color: '#D05303' }} variant="subtitle2">
              Cidade/UF
            </Typography>
            <S.TypographyStyled>
              {client?.city} / {client?.uf}
            </S.TypographyStyled>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

HeaderCompanyDetails.prototype = {
  client: PropTypes.object.isRequired
};
