import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page } from 'src/components';
import { billing } from 'src/services';
import { useBillingsToSaveStore } from 'src/services/store';
import BillingForm from '../components/BillingForm';
import {
  BillingFormContainer,
  ButtonsContainer,
  HorizontalLine,
  SendBillingContainer
} from './styles';

export default function EditBilling() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const billingsIds = searchParams.getAll('id');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const billingsToEdit = useBillingsToSaveStore((state) => state.billingsToSave);
  const disableSave = useBillingsToSaveStore((state) => state.disableSave);
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const resultsArr = [];
        for (let i = 0; i < billingsIds.length; i++) {
          const result = await billing.getBill(billingsIds[i]);
          if (result) {
            result.emailList = [];
            result.newFiles = [];
            result.origin = result.bill[0].origin;
            resultsArr.push(result);
          }
        }
        useBillingsToSaveStore.setState((state) => ({ billingsToSave: resultsArr }));
        useBillingsToSaveStore.setState({ disableSave: false });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    load();
  }, []);

  const removeBilling = (index) => {
    const update = billingsToEdit.filter((billing) => billing.id !== index);
    useBillingsToSaveStore.setState({ billingsToSave: update });
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < billingsToEdit.length; i++) {
        let billsList = billingsToEdit[i].bill;
        const values = Object.values(billingsToEdit[i].newFiles);
        if (values.length > 0) {
          const billingOrigin = billingsToEdit[i].origin;
          for (let j = 0; j < values.length; j++) {
            let newBillFiles;
            if (values[j].ticketInfo) {
              newBillFiles = await billing.sendFiles(
                [values[j].file],
                values[j].fileType,
                billingOrigin,
                values[j].ticketInfo
              );
            } else {
              newBillFiles = await billing.sendFiles(
                [values[j].file],
                values[j].fileType,
                billingOrigin
              );
            }
            billsList = [...billsList, ...newBillFiles];
          }
        }
        await billing.update(
          billingsToEdit[i].id,
          billsList,
          billingsToEdit[i].dueDate,
          billingsToEdit[i].trackingId,
          billingsToEdit[i].billCode,
          billingsToEdit[i].billValue,
          billingsToEdit[i].buyOrder
        );
      }
      setLoading(false);
      return setMessage('Fatura alterada com sucesso');
    } catch (error) {
      console.error(error);
      setLoading(false);
      return setMessage('Algo deu errado, refaça a operação');
    }
  };

  const handleCancel = () => {
    useBillingsToSaveStore.setState((state) => ({ billingsToSave: [] }));
    useBillingsToSaveStore.setState((state) => ({ disableSave: true }));
    navigate('/billing');
  };

  return (
    <Page title="Editar Fatura" loading={loading}>
      <SendBillingContainer>
        <Typography variant="h3">Editar fatura</Typography>
        <HorizontalLine />
        {billingsToEdit.map((billing, index) => (
          <BillingFormContainer key={index}>
            <BillingForm
              billIndex={billing.id}
              key={billing.id}
              removeBilling={() => removeBilling(billing.id)}
              billingTitle={index}
              billingData={billing}
              formType="edit"
            />
          </BillingFormContainer>
        ))}
        <ButtonsContainer>
          <Button variant="contained" color="error" onClick={() => handleCancel()}>
            Cancelar
          </Button>
          <Button disabled={disableSave} variant="contained" onClick={() => handleEdit()}>
            Salvar
          </Button>
        </ButtonsContainer>
      </SendBillingContainer>
      <Dialog
        open={message !== null}
        onClose={() => navigate('/billing')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fatura</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
