import 'simplebar/dist/simplebar.min.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './App';

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
