import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Modal, Typography, Button, CircularProgress } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, Close, Download } from '@mui/icons-material';
import { useAuth } from '../../../contexts';
import { billing, internalLogs } from '../../../services';

const BillTypes = {
  rent: 'Locação',
  excess: 'Km Excedente',
  refund: 'Reembolso',
  ticket: 'Multa'
};

let toShow = 0;

export default function Visualize({ data, open, close }) {
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState({ fileURL: null, pdf: false });
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const { profile } = useAuth();

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const { fileURL, pdf } = await billing.getDoc(data.bill[toShow].key);

      setFiles([{ fileURL, pdf }]);
      setShow({ fileURL, pdf });
      setLoading(false);
    };

    if (files.length === 0) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const onClose = () => {
    setFiles([]);
    setShow(null);
    toShow = 0;

    close();
  };

  // eslint-disable-next-line consistent-return
  const nextDocument = async () => {
    toShow += 1;

    if (!files[toShow]) {
      setLoading(true);
      const { fileURL, pdf } = await billing.getDoc(data.bill[toShow].key);
      setFiles([...files, { fileURL, pdf }]);
      setLoading(false);
      return setShow({ fileURL, pdf });
    }

    setShow(files[toShow]);
  };

  const previousDocument = () => {
    toShow -= 1;

    setShow(files[toShow]);
  };

  const sendLog = async () => {
    const notify = {
      userId: profile.id,
      name: profile.name,
      companyName: profile.company,
      companyDocument: profile.document,
      action: 'billing downloaded',
      protocol: data.protocol,
      description: `O usuário ${profile.name.toUpperCase()}, da empresa ${profile.company.toUpperCase()}, baixou a fatura de número ${
        data.protocol
      }`
    };

    await internalLogs.create(notify);
  };

  const downloadDoc = async () => {
    setDownload(true);

    await sendLog();

    const link = document.createElement('a');
    link.href = show.fileURL;
    link.download = BillTypes[data.bill[toShow].origin];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(show.fileURL);

    setTimeout(() => setDownload(false), 500);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          height: '90%',
          width: '90%',
          backgroundColor: 'background.paper',
          borderRadius: '10px',
          padding: '10px',
          boxShadow: 24
        }}
      >
        <Box sx={{ padding: '10px', textAlign: 'center' }}>
          <Typography variant="h5">{data.clientName}</Typography>
          <Typography variant="body1">
            {new Date(data.createdAt).toLocaleDateString('pt-BR', {
              timeZone: 'America/Sao_Paulo'
            })}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => onClose()}
            size="medium"
            sx={{ position: 'fixed', top: '10px', right: '10px' }}
          >
            <Close />
          </IconButton>
        </Box>
        {loading ? (
          <CircularProgress
            sx={{
              display: 'flex',
              margin: 'auto'
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '90%',
              width: '100%',
              margin: '10px auto'
            }}
          >
            <Typography variant="h5">
              Arquivo {toShow + 1} - {BillTypes[data.bill[toShow].origin]}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: '1',
                width: '100%'
              }}
            >
              {show.pdf ? (
                <iframe
                  title="Documento"
                  src={`${show.fileURL}#toolbar=0`}
                  height="90%"
                  width="90%"
                />
              ) : (
                <Box
                  sx={{
                    width: '90%',
                    height: '90%',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="h2">Documento não visualizável</Typography>
                  <Typography variant="h4">Faça o download para ver</Typography>
                </Box>
              )}
              {download && (
                <iframe title="Documento" src={`${show.fileURL}#toolbar=0`} height="1" width="1" />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 4rem'
              }}
            >
              <Button
                color="primary"
                disabled={data.bill.length === 1 || toShow === 0}
                onClick={previousDocument}
                variant="outlined"
              >
                <ArrowBackIos fontSize="3rem" />
                <Typography fontSize="1rem" fontWeight="bold">
                  Anterior
                </Typography>
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  width: '100%'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadDoc}
                  // disabled={show.pdf}
                  endIcon={<Download />}
                >
                  Salvar Documento
                </Button>
              </Box>
              <Button
                color="primary"
                disabled={data.bill.length === 1 || toShow === data.bill.length - 1}
                onClick={nextDocument}
                variant="outlined"
              >
                <Typography fontSize="1rem" fontWeight="bold">
                  Próximo
                </Typography>
                <ArrowForwardIos fontSize="3rem" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

Visualize.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func
};
