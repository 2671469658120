import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { user as userService } from '../../../../../services';
import { columns } from './columns';

export default function UserSection({ document, setLoading }) {
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!document) return;
      setLoading(true);
      const usersData = await userService.findAll({ active: true, document });
      setUsers(usersData.data);
      setLoading(false);
    })();
  }, [document]);

  return (
    <Grid item container xs={12}>
      <Box
        style={{
          width: '100%',
          display: 'block',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            marginTop: '1rem',
            fontSize: '30px'
          }}
        >
          Usuários Vinculados
        </Typography>
        <hr
          style={{
            border: 0,
            borderTop: '2px solid #E77E22',
            marginTop: '1.3rem',
            marginBottom: '2.5rem'
          }}
        />
      </Box>
      {users && users?.length ? (
        <Grid container item xs={12}>
          <DataGrid
            sx={{
              border: 'none',
              borderBottom: 'hidden !important',
              '& .MuiDataGrid-cell': {
                backgroundColor: '#F2F4F7',
                borderRadius: '8px',
                marginLeft: '0.1rem',
                justifyContent: 'center'
              },
              '& .MuiDataGrid-row': {
                marginBottom: '0.1rem'
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#D0D5DD',
                borderRadius: '8px',
                marginLeft: '0.1rem'
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                justifyContent: 'center'
              },
              '&>.MuiDataGrid-main': {
                '&>.MuiDataGrid-columnHeaders': {
                  borderBottom: 'none'
                }
              }
            }}
            columns={columns}
            rows={users}
            autoHeight
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            components={{
              cell: ({ value, field }) => (
                <div style={{ borderTop: 'none', borderBottom: 'none' }}> {value[field]} </div>
              )
            }}
          />
        </Grid>
      ) : (
        <Grid container item xs={12} justifyContent="center" display="flex">
          <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
            <Typography variant="h6">Ops... não foram encontrados usuários vinculados.</Typography>
          </Grid>
          <Button
            sx={{ borderRadius: '20px' }}
            variant="contained"
            onClick={() => navigate('/user')}
          >
            Adicionar Usuário
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

UserSection.propTypes = {
  document: PropTypes.string,
  setLoading: PropTypes.func
};
