import { Box, Grid, Container, Typography } from '@mui/material';

import { Page } from '../../../components';
import { PasswordForm } from './components';

export default function Password() {
  return (
    <Page title="Redefinir senha">
      <Container maxWidth="xl">
        <Grid item xs={12} md={10} lg={6} margin={2}>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Senha
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Alterar senha</Typography>
          </Box>
          <PasswordForm />
        </Grid>
      </Container>
    </Page>
  );
}
