import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { LogoAlt } from '../../../components';

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }} marginBottom={16}>
      <AppBar style={{ backgroundColor: '#19213A' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: 6,
            marginBottom: 6
          }}
        >
          <LogoAlt />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            CADASTRO DO CLIENTE
          </Typography>
          <div style={{ width: '25%', height: '2px', background: 'white' }} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
