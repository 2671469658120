import { create } from 'zustand';

export const useBillingsToSaveStore = create((set) => ({
  billingsToSave: [],
  disableSave: true
}));

export const verifyFields = (billingsList) => {
  let disable = true;
  const billsList = Object.values(billingsList);

  if (billsList.length === 0) {
    return disable;
  }

  for (let i = 0; i < billsList.length; i++) {
    if (
      billsList[i].billingType !== null &&
      billsList[i].billCode !== null &&
      billsList[i].billCode !== '' &&
      billsList[i].dueDate !== null &&
      (billsList[i].dueDate instanceof Date && !isNaN(billsList[i].dueDate)) === true &&
      billsList[i].trackingId !== null &&
      billsList[i].billValue !== null
    ) {
      const values = Object.values(billsList[i].files);
      if (values.length > 0) {
        for (let j = 0; j < values.length; j++) {
          if (values[j].ticketInfo) {
            if (
              values[j].fileType === null ||
              values[j].file === null ||
              values[j].ticketInfo.vehiclePlate === null ||
              values[j].ticketInfo.vehiclePlate === '' ||
              values[j].ticketInfo.ticketLevel === null ||
              values[j].ticketInfo.limitIdentificationDate === null ||
              (values[j].ticketInfo.limitIdentificationDate instanceof Date &&
                !isNaN(values[j].ticketInfo.limitIdentificationDate)) === false ||
              values[j].ticketInfo.ait === null
            ) {
              disable = true;
              break;
            } else {
              disable = false;
            }
          } else if (values[j].fileType === null || values[j].file === null) {
            disable = true;
            break;
          } else {
            disable = false;
          }
        }
      } else {
        disable = true;
        break;
      }
    } else {
      disable = true;
    }
  }
  return disable;
};

const validateSendFields = (billing) => {
  if (
    billing.billCode &&
    billing.billCode !== null &&
    billing.billCode !== '' &&
    billing.dueDate !== null &&
    billing.trackingId &&
    billing.trackingId !== null &&
    billing.trackingId !== '' &&
    billing.billValue &&
    billing.billValue !== null &&
    billing.billValue !== ''
  ) {
    return true;
  }
  return false;
};

export const verifySendFields = (billingsList) => {
  let disable = true;
  if (billingsList.length === 0) {
    return disable;
  }

  billingsLoop: for (let i = 0; i < billingsList.length; i++) {
    if (!billingsList[i].emailList || billingsList[i].emailList.length === 0) {
      disable = true;
      break;
    }

    if (billingsList[i].bill.length === 0 && Object.values(billingsList[i].newFiles).length === 0) {
      disable = true;
      break;
    }

    for (let k = 0; k < billingsList[i].bill.length; k++) {
      if (!billingsList[i].bill[k].fileType) {
        disable = true;
        break;
      }
    }

    const validated = validateSendFields(billingsList[i]);

    if (validated) {
      const newFiles = Object.values(billingsList[i].newFiles);
      if (newFiles.length > 0) {
        for (let j = 0; j < newFiles.length; j++) {
          if (newFiles[j].fileType === null || newFiles[j].file === null) {
            disable = true;
            break billingsLoop;
          }
          if (
            newFiles[j].ticketInfo &&
            (newFiles[j].ticketInfo.vehiclePlate === null ||
              newFiles[j].ticketInfo.vehiclePlate === '' ||
              newFiles[j].ticketInfo.ticketLevel === null ||
              newFiles[j].ticketInfo.limitIdentificationDate === null ||
              newFiles[j].ticketInfo.ait === null ||
              newFiles[j].ticketInfo.ait === '')
          ) {
            disable = true;
            break billingsLoop;
          } else {
            disable = false;
          }
        }
      } else {
        disable = false;
      }
    } else {
      disable = true;
    }
  }
  return disable;
};

const validateEditFields = (billing) => {
  if (
    billing.billCode !== null &&
    billing.billCode !== '' &&
    billing.billCode &&
    billing.dueDate !== null &&
    billing.trackingId &&
    billing.trackingId !== null &&
    billing.trackingId !== '' &&
    billing.billValue &&
    billing.billValue !== null &&
    billing.billValue !== ''
  ) {
    return true;
  }
  return false;
};

export const verifyEditFields = (billingsList) => {
  let disable = true;
  if (billingsList.length === 0) {
    return disable;
  }

  billingsLoop: for (let i = 0; i < billingsList.length; i++) {
    for (let k = 0; k < billingsList[i].bill.length; k++) {
      if (!billingsList[i].bill[k].fileType) {
        disable = true;
        break;
      }
    }

    if (billingsList[i].bill.length === 0 && Object.values(billingsList[i].newFiles).length === 0) {
      disable = true;
      break;
    }

    const validated = validateEditFields(billingsList[i]);

    if (validated) {
      const newFiles = Object.values(billingsList[i].newFiles);
      if (newFiles.length > 0) {
        for (let j = 0; j < newFiles.length; j++) {
          if (newFiles[j].fileType === null || newFiles[j].file === null) {
            disable = true;
            break billingsLoop;
          }
          if (
            newFiles[j].ticketInfo &&
            (newFiles[j].ticketInfo.vehiclePlate === null ||
              newFiles[j].ticketInfo.vehiclePlate === '' ||
              newFiles[j].ticketInfo.ticketLevel === null ||
              newFiles[j].ticketInfo.limitIdentificationDate === null ||
              newFiles[j].ticketInfo.ait === null ||
              newFiles[j].ticketInfo.ait === '')
          ) {
            disable = true;
            break billingsLoop;
          } else {
            disable = false;
          }
        }
      } else {
        disable = false;
      }
    } else {
      disable = true;
    }
  }
  return disable;
};
