import jwt from 'jwt-decode';

import * as api from './api';

const signIn = async (email, password) => {
  const { token, refreshToken } = await api.post('auth/sign-in', { email, password });

  if (!token) return { profile: null, token: null, refreshToken: null };

  const profile = jwt(token);

  setAuthInLocalStorage(token, refreshToken, profile);

  return { profile, token, refreshToken };
};

const signOut = () => {
  localStorage.clear();
};

const forgot = async (phone) => {
  try {
    phone = phone.replace(/\D|\s/g, '');
    const { statusCode } = await api.get(`auth/recover?phone=${phone}`);
    if (statusCode && statusCode !== 200) {
      return false;
    }

    return true;
  } catch ({ message }) {
    console.error('[FORGOT]', message);
    return false;
  }
};

const reset = async ({ phone, token, password }) => {
  try {
    const { statusCode } = await api.post(`auth/reset?phone=${phone}`, {
      token: parseInt(token.trim(), 10),
      password: password.trim()
    });

    if (statusCode && statusCode !== 200) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

const getSession = () => {
  const session = { token: null, profile: null, refreshToken: null };

  const token = localStorage.getItem('@seteloc:token');
  const refreshToken = localStorage.getItem('@seteloc:refreshToken');
  const profile = localStorage.getItem('@seteloc:profile');

  if (token && session) {
    session.token = token;
    session.profile = JSON.parse(profile);
    session.refreshToken = refreshToken;
  }

  return session;
};

const refreshToken = async () => {
  const { refreshToken } = getSession();
  const response = await api.post('auth/refresh-token', {
    refreshToken
  });
  if (response?.statusCode && response?.statusCode !== '200') return null;
  const { token, refreshToken: newRefreshToken } = response;
  setAuthInLocalStorage(token, newRefreshToken);
  return { token, refreshToken: newRefreshToken };
};

const setAuthInLocalStorage = (token, refreshToken, profile) => {
  localStorage.setItem('@seteloc:authorized', true);
  localStorage.setItem('@seteloc:token', `Bearer ${token}`);
  localStorage.setItem('@seteloc:refreshToken', refreshToken);
  if (profile) localStorage.setItem('@seteloc:profile', JSON.stringify(profile));
};

export default { signIn, signOut, forgot, reset, getSession, refreshToken };
