import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';

const VisualizedFilter = ({ filters, setFilters }) => (
  <Box>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Foi visualizado?</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filters.isVisualized}
        label="Foi visualizado?"
        onChange={(event) => setFilters({ ...filters, isVisualized: event.target.value })}
        style={{ width: '15vw' }}
      >
        <MenuItem value="visualized">Visualizado</MenuItem>
        <MenuItem value="notVisualized">Não visualizado</MenuItem>
      </Select>
    </FormControl>
  </Box>
);

VisualizedFilter.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func
};

export default VisualizedFilter;
