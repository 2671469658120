import React, { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { toast } from 'react-toastify';

import { Page } from '../../../components';
import { customers, internalLogs } from '../../../services';

export default function LogsNotification() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientSearch, setClientSearch] = useState();

  const logType = {
    'billing vizualized': 'Fatura Visualizada',
    'deleted user': 'usuario deletado'
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const logs = await internalLogs.findLasts();
      setData(logs);

      const doc = await customers.getCustomersTotvs();
      if (!doc) toast.error('Não foram encontrados clientes.');

      setClients(doc);
      setLoading(false);
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeClient = async () => {
    const documentarray = clientSearch?.split(' - ');
    const document = documentarray[documentarray.length - 1];

    const logs = await internalLogs.findByDocument(document);
    setData(logs);
  };

  const clientsOptions = useMemo(
    () =>
      [...new Set(clients?.map((client) => `${client?.name} - ${client?.document}`).sort())] || [],
    [clients]
  );

  const loadLogs = () => {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    if (data?.length === 0) {
      return (
        <Typography variant="h6" color="primary">
          Nenhum log recuperado ainda
        </Typography>
      );
    }

    return data?.map((log) => {
      const date = new Date(log.createdAt);
      return (
        <Paper elevation={2} sx={{ marginTop: 1 }} key={log.id}>
          <Box padding={1}>
            <Typography variant="h6" color="primary">
              {logType[log.action]}
            </Typography>
            <Typography marginTop={1}>{log.description}</Typography>
            <Typography fontSize={14}>
              {`${date.toLocaleDateString('pt-BR', {
                timeZone: 'America/Sao_Paulo'
              })} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}
            </Typography>
          </Box>
        </Paper>
      );
    });
  };

  return (
    <Page loading={loading} title="Logs">
      <Container>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Paper sx={{ p: 1, alignItems: 'center', width: '100%' }} elevation={3}>
              <Grid item margin={1}>
                <Grid item marginBottom={1}>
                  <Typography variant="h3">Log de Ações</Typography>
                </Grid>
                <Grid
                  display="flex"
                  lexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={10}>
                    <Autocomplete
                      options={clientsOptions}
                      id="box-client-select"
                      onInputChange={(e, value) => {
                        setClientSearch(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Nome do cliente" variant="filled" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                    <Button variant="outlined" onClick={changeClient}>
                      Pesquisar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper sx={{ p: 1, alignItems: 'center', width: '100%', marginTop: 1 }} elevation={3}>
              {loadLogs()}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
