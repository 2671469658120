import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  TextField,
  createFilterOptions
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useBillingsToSaveStore, verifyFields } from 'src/services/store';
import { billingOptions, ticketLevelOptions } from '../../options';
import {
  BillingFileButton,
  BillingInputContainer,
  CancelButton,
  NewBillingFormControl,
  NewBillingInput,
  NewBillingSelect
} from './style';

export default function HandleSaveFile(props) {
  const { billType, removeInput, billIndex, inputIndex, vehiclesList } = props;
  const [limitIdentificationDate, setLimitIdentificationDate] = useState(null);
  const [filePreviewName, setFilePreviewName] = useState('');
  const [open, setOpen] = useState(false);
  const [filePreview, setFilePreview] = useState('');
  const [plate, setPlate] = useState('');
  const [ait, setAit] = useState(null);
  const [ticketLevel, setTicketLevel] = useState(null);
  const [fileType, setFileType] = useState(null);
  const billingsToSave = useBillingsToSaveStore((state) => state.billingsToSave);

  useEffect(() => {
    setFileType(billingsToSave[billIndex].files[inputIndex].fileType);
    if (billingsToSave[billIndex].files[inputIndex].ticketInfo) {
      setPlate(billingsToSave[billIndex].files[inputIndex].ticketInfo.vehiclePlate);
      setAit(billingsToSave[billIndex].files[inputIndex].ticketInfo.ait);
      setTicketLevel(billingsToSave[billIndex].files[inputIndex].ticketInfo.ticketLevel);
      setLimitIdentificationDate(
        billingsToSave[billIndex].files[inputIndex].ticketInfo.limitIdentificationDate
      );
    }
  }, [billingsToSave, billIndex, inputIndex]);

  const filter = createFilterOptions();

  const options = billingOptions[billType];

  const handleFilePreview = (index) => {
    setOpen(true);
    setFilePreviewName(billingsToSave[billIndex].files[index].file.name);
    const { file } = billingsToSave[billIndex].files[index];

    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileTypeChange = (index, value) => {
    const billings = billingsToSave;
    billings[billIndex].files[index].fileType = value;
    useBillingsToSaveStore.setState({ billingsToSave: billings });
    setFileType(value);
    if (value == 'notification' || value == 'nic' || value == 'infringement') {
      billings[billIndex].files[index].ticketInfo = {
        vehiclePlate: plate,
        ait,
        ticketLevel,
        limitIdentificationDate
      };
    } else {
      delete billings[billIndex].files[index].ticketInfo;
    }

    const isDisabled = verifyFields(billingsToSave);
    useBillingsToSaveStore.setState({ disableSave: isDisabled });
  };

  const handleAddNotificationData = (index, value, field) => {
    const billings = billingsToSave;

    if (value === '') {
      value = null;
    }

    switch (field) {
      case 'limitIdentificationDate':
        setLimitIdentificationDate(value);
        break;
      case 'vehiclePlate':
        setPlate(value);
        break;
      case 'ait':
        setAit(value);
        break;
      case 'ticketLevel':
        setTicketLevel(value);
        break;
      case 'fileType':
        setFileType(value);
        break;
      default:
        break;
    }

    billings[billIndex].files[index].ticketInfo[field] = value;
    useBillingsToSaveStore.setState({ billingsToSave: billings });

    const isDisabled = verifyFields(billingsToSave);
    useBillingsToSaveStore.setState({ disableSave: isDisabled });
  };

  const handleRemoveInput = () => {
    removeInput(inputIndex);
  };

  const handleInputPlates = (event, newValue) => {
    if (!vehiclesList.includes(newValue)) {
      setPlate(null);
      handleAddNotificationData(inputIndex, null, 'vehiclePlate');
      event.preventDefault();
      return;
    }
    if (typeof newValue === 'string') {
      handleAddNotificationData(inputIndex, newValue, 'vehiclePlate');
      setPlate(newValue);
    } else if (newValue && newValue.inputValue) {
      handleAddNotificationData(inputIndex, newValue.inputValue, 'vehiclePlate');
      setPlate(newValue.inputValue);
    } else {
      handleAddNotificationData(inputIndex, newValue, 'vehiclePlate');
      setPlate(newValue);
    }
  };
  return (
    <>
      <BillingInputContainer>
        <BillingFileButton
          startIcon={<DescriptionIcon />}
          onClick={() => handleFilePreview(inputIndex)}
        >
          {billingsToSave[billIndex].files[inputIndex].file
            ? billingsToSave[billIndex].files[inputIndex].file.name
            : ''}
        </BillingFileButton>
        <NewBillingFormControl>
          <InputLabel shrink={!!fileType} id="bill-types">
            Tipo de arquivo
          </InputLabel>
          <NewBillingSelect
            labelId="bill-types"
            label="Tipo de arquivo"
            value={fileType}
            onChange={(e) => handleFileTypeChange(inputIndex, e.target.value)}
          >
            {options &&
              options.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
          </NewBillingSelect>
        </NewBillingFormControl>
        {(fileType === 'notification' || fileType === 'nic' || fileType === 'infringement') && (
          <>
            <Autocomplete
              selectOnFocus
              clearOnBlur
              freeSolo
              handleHomeEndKeys
              options={vehiclesList}
              value={plate}
              sx={{ width: 300 }}
              onChange={(event, newValue) => handleInputPlates(event, newValue)}
              renderInput={(params) => <TextField {...params} label="Placa do Veículo" />}
            />
            <NewBillingInput
              type="text"
              value={ait}
              onChange={(e) => handleAddNotificationData(inputIndex, e.target.value, 'ait')}
              placeholder="AIT"
              label="AIT"
            />
            <NewBillingFormControl>
              <InputLabel id="ticket-level">Gravidade</InputLabel>
              <NewBillingSelect
                labelId="ticket-level"
                label="Gravidade da Multa"
                value={ticketLevel}
                onChange={(e) =>
                  handleAddNotificationData(inputIndex, e.target.value, 'ticketLevel')
                }
              >
                {ticketLevelOptions.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.key}
                  </MenuItem>
                ))}
              </NewBillingSelect>
            </NewBillingFormControl>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={limitIdentificationDate}
                label="Data limite para identificação"
                onChange={(value) => {
                  handleAddNotificationData(inputIndex, value, 'limitIdentificationDate');
                }}
                renderInput={(params) => (
                  <NewBillingInput
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Data limite para identificação'
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        )}
        <CancelButton color="error" variant="contained" onClick={() => handleRemoveInput()}>
          <CloseIcon />
        </CancelButton>
      </BillingInputContainer>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Preview de {filePreviewName}</DialogTitle>
        <DialogContent
          sx={{
            height: '700px'
          }}
        >
          {filePreview && (
            <iframe src={filePreview} title={filePreviewName} height="100%" width="100%" />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

HandleSaveFile.propTypes = {
  billType: PropTypes.string.isRequired,
  removeInput: PropTypes.func.isRequired,
  inputIndex: PropTypes.string.isRequired,
  billIndex: PropTypes.string.isRequired,
  vehiclesList: PropTypes.array
};
