import { ptBr } from './ptBR/pt-br';

export function Translate(text, lang = 'ptBr') {
  if (!text) return '';
  if (lang && lang !== 'ptBr') return '';
  const translated = ptBr[text.toUpperCase()];

  return translated || text;
}

export function TranslateText(text) {
  const translatedText = text.split(' ').map((word) => Translate(word));
  return translatedText.join(' ');
}
