import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import React from 'react';

const handleChange = (e, setFilters, filters) => {
  const regex = /^[0-9\b]+$/;
  if (e.target.value === '' || regex.test(e.target.value)) {
    setFilters({ ...filters, billCode: e.target.value });
  }
};

const BillCodeFilter = ({ filters, setFilters }) => (
  <Box>
    <FormControl fullWidth>
      <TextField
        label="Número da fatura"
        name="billCode"
        variant="outlined"
        onChange={(event) => handleChange(event, setFilters, filters)}
        value={filters.billCode}
        sx={{ display: 'flex' }}
      />
    </FormControl>
  </Box>
);

BillCodeFilter.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func
};

export default BillCodeFilter;
