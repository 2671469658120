export const MIN_CHARACTERS_REGEX = /.{8,}/;
export const LOWER_CASE_REGEX = /[a-z]/;
export const SPECIAL_CHARACTER_REGEX = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
export const UPPER_CASE_REGEX = /[A-Z]/;
export const NUMBER_REGEX = /[0-9]/;
export const MAX_CHARACTERS_REGEX = /^.{0,16}$/;
export const SPACE_NOT_ALLOWED = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;

export function validatePasswordSecurity(value) {
  return (
    MIN_CHARACTERS_REGEX.test(value) &&
    LOWER_CASE_REGEX.test(value) &&
    SPECIAL_CHARACTER_REGEX.test(value) &&
    UPPER_CASE_REGEX.test(value) &&
    NUMBER_REGEX.test(value) &&
    MAX_CHARACTERS_REGEX.test(value) &&
    SPACE_NOT_ALLOWED.test(value)
  );
}
