import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Box, Button, Divider, Modal, TextField, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function TechnicianModal({ open, handleClose, users, clientId }) {
  const [technicians, setTechnicians] = useState([]);

  const handleUpdate = async () => {
    try {
      toast.success('Técnico atualizado com sucesso');
      window.location.reload();
    } catch (e) {
      toast.error('Erro ao atualizar técnico');
    }
  };
  const handleTechniciansChange = (event, newValue) => {
    setTechnicians(newValue);
  };

  const handleDelete = (technicianToDelete) => () => {
    setTechnicians((technicians) =>
      technicians.filter((technician) => technician.id !== technicianToDelete.id)
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          height: '400px',
          width: '600px',
          borderRadius: '20px'
        }}
      >
        <Box mb={5}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Novo Técnico
          </Typography>
          <Divider color="#E77E22" variant="fullWidth" />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Autocomplete
            multiple
            placeholder="Digite o cliente"
            sx={{ width: 600, margin: 'auto' }}
            renderInput={(params) => <TextField {...params} label="Técnicos" />}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            options={users}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/prop-types
              <li {...props} key={props?.id}>
                {option.firstName} {option.lastName}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={technicians}
            onChange={handleTechniciansChange}
            renderTags={() => null}
          />
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}>
          {technicians.map((technician) => (
            <Chip
              key={technician.id}
              label={`${technician.firstName} ${technician.lastName}`}
              onDelete={handleDelete(technician)}
              deleteIcon={<CancelIcon />}
              sx={{ margin: '0.5rem' }}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ position: 'absolute', bottom: '1rem', left: '1rem', width: 'calc(100% - 2rem)' }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: '1rem',
              borderRadius: '20px',
              width: '94px'
            }}
            onClick={handleClose}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: '1rem',
              borderRadius: '20px'
            }}
            onClick={handleUpdate}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

TechnicianModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  clientId: PropTypes.string
};
