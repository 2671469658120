import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Marker = ({ ...options }) => {
  const [info, setInfo] = useState();
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      const { color } = options;

      const marker = new window.google.maps.Marker({
        animation: window.google.maps.Animation.DROP,
        icon: {
          url: `/static/point/${color || 'orange'}.svg`,
          scaledSize: new window.google.maps.Size(23, 32),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(23, 32)
        },
        optimized: true
      });

      if (options.content) {
        const info = new window.google.maps.InfoWindow();
        marker.addListener('click', () => {
          info.close();
          info.open(marker.getMap(), marker);
        });

        setInfo(info);
      }
      setMarker(marker);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }

      if (info) {
        info.setMap(null);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }

    if (info) {
      info.setContent(options.content);
    }
  }, [marker, info, options]);

  return null;
};

Marker.propTypes = {
  options: PropTypes.object
};
