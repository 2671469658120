import PropTypes from 'prop-types';
import React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateFilter = ({ filters, setFilters }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DesktopDatePicker
      inputFormat="DD/MM/YYYY"
      label="Data de início"
      value={filters.startDate}
      onChange={(date) => {
        setFilters({ ...filters, startDate: date });
      }}
      renderInput={(params) => <TextField name="startDate" {...params} />}
    />
    <div style={{ marginLeft: '10px' }} />
    <DesktopDatePicker
      inputFormat="DD/MM/YYYY"
      minDate={filters.startDate}
      label="Data de fim"
      value={filters.endDate}
      onChange={(date) => {
        setFilters({ ...filters, endDate: date });
      }}
      renderInput={(params) => <TextField name="endDate" {...params} />}
    />
  </LocalizationProvider>
);

DateFilter.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func
};

export default DateFilter;
