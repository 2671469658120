import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PasswordCriteria from './PasswordCriteria';

const Form = ({ form, setForm, verifyPasswordMatch, acceptTerms, setAcceptTerms }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [areaTextOpen, setAreaTextOpen] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    if (name === 'area' && value === 'Outros') {
      setAreaTextOpen(true);
      setForm({
        ...form,
        [name]: value
      });
    } else {
      setForm({
        ...form,
        [name]: value
      });
    }
  };

  return (
    <Grid container spacing={4} sx={{ overflowY: 'auto' }}>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={handleChangeForm}
          required
          fullWidth
          id="firstName"
          label="Nome"
          name="firstName"
          value={form.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={handleChangeForm}
          required
          fullWidth
          id="lastName"
          label="Sobrenome"
          name="lastName"
          value={form.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask mask="999.999.999-99" onChange={handleChangeForm} value={form.cpf}>
          {() => <TextField required fullWidth id="cpf" label="CPF" name="cpf" />}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel id="area">Área</InputLabel>
          <Select
            id="area"
            value={form.area}
            label="Área"
            name="area"
            onChange={handleChangeForm}
            disabled={areaTextOpen}
            required
          >
            <MenuItem value="Financeiro">Financeiro</MenuItem>
            <MenuItem value="Operação">Operação</MenuItem>
            <MenuItem value="Administrativo">Administrativo</MenuItem>
            <MenuItem value="Diretoria">Diretoria</MenuItem>
            <MenuItem value="Outros">Outros</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        {areaTextOpen ? (
          <TextField
            onChange={handleChangeForm}
            required
            fullWidth
            id="area"
            label="Área"
            name="area"
            autoComplete="area"
          />
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={handleChangeForm}
          required
          fullWidth
          id="email"
          label="E-mail"
          name="email"
          value={form.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask mask="(99) 9 9999 9999" onChange={handleChangeForm} value={form.phone}>
          {() => <TextField required fullWidth name="phone" id="phone" label="Celular" />}
        </InputMask>
      </Grid>

      <Grid item xs={12}>
        <InputMask mask="99.999.999/9999-99" onChange={handleChangeForm} value={form.cnpj}>
          {() => (
            <TextField
              required
              fullWidth
              name="cnpj"
              label="CNPJ da empresa"
              type="cnpj"
              id="cnpj"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={handleChangeForm}
          required
          fullWidth
          id="password"
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          value={form.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={handleChangeForm}
          required
          fullWidth
          id="confirmPassword"
          label="Confirmação de senha"
          name="confirmPassword"
          type={showPassword ? 'text' : 'password'}
          value={form.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              onClick={() => setAcceptTerms(!acceptTerms)}
              value="allowExtraEmails"
              color="primary"
            />
          }
          label="Li e estou de acordo com o tempo de uso e política de privacidade"
        />
        <Typography style={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
          <LockIcon style={{ marginRight: '8px' }} />
          Sua senha deve cumprir os critérios mínimos abaixo:
        </Typography>

        <PasswordCriteria password={form.password} passwordMatch={verifyPasswordMatch} />
      </Grid>
    </Grid>
  );
};

Form.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  verifyPasswordMatch: PropTypes.func,
  acceptTerms: PropTypes.bool,
  setAcceptTerms: PropTypes.func
};

export default Form;
