import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material/styles/';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { publicUser } from '../../services';
import palette from '../../theme/palette';
import shadows, { customShadows } from '../../theme/shadows';
import shape from '../../theme/shape';
import typography from '../../theme/typography';
import { cnpjRegex, cpfRegex, passwordRegexAndCriteria, regexValidation } from '../../utils/regex';
import {
  removeSpecialCharactersFromCnpj,
  removeSpecialCharactersFromCpf,
  removeSpecialCharactersFromPhone
} from '../../utils/removeSpecialCharacters';
import Form from './components/Form';
import Header from './components/Header';

export default function ClientRegister() {
  const theme = createTheme({
    palette,
    shape,
    typography,
    shadows,
    customShadows
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { hasRedirect, from } = location?.state || {};

  const path = hasRedirect ? from.replace('/redirect', '') : '/clients';

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    cpf: '',
    area: '',
    email: '',
    phone: '',
    cnpj: '',
    password: '',
    confirmPassword: ''
  });

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifyPasswordMatch = () => form.password === form.confirmPassword;

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    cpf: yup.string().required().matches(cpfRegex, 'CPF inválido'),
    area: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    cnpj: yup.string().required().matches(cnpjRegex, 'CNPJ inválido'),
    password: yup.string().required().matches(form.confirmPassword, 'As senhas são diferentes'),
    confirmPassword: yup.string().required()
  });

  const registerClientUser = async () => {
    try {
      const response = await publicUser.create({
        firstName: form.firstName,
        lastName: form.lastName,
        cpf: removeSpecialCharactersFromCpf(form.cpf),
        area: form.area,
        email: form.email,
        phone: removeSpecialCharactersFromPhone(form.phone),
        document: removeSpecialCharactersFromCnpj(form.cnpj),
        password: form.password
      });
      if (response.statusCode) {
        toast.error(response.message);
      } else {
        toast.success('SUCESSO! Em breve você receberá o acesso ao Painel do Gestor');
        navigate('/confirmation');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (isPasswordAcceptAllCriteria()) {
      schema
        .validate(form)
        .then(registerClientUser)
        .catch((err) => toast.error(err.message));
      setLoading(false);
    } else {
      toast.error('A senha não respeita todos os critérios necessários');
      setLoading(false);
    }

    toast.error('CNPJ não encontrado');
    setLoading(false);
  };

  const isPasswordAcceptAllCriteria = () => {
    const acceptCriterias = passwordRegexAndCriteria.map((criteria) =>
      regexValidation(criteria.regex, form.password)
    );

    return !acceptCriterias.includes(false) && form.password === form.confirmPassword;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" width="xs">
          <Header />
          <form onSubmit={handleSubmit.bind()}>
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box sx={{ mt: 3 }}>
                <Form
                  form={form}
                  setForm={setForm}
                  verifyPasswordMatch={verifyPasswordMatch}
                  acceptTerms={acceptTerms}
                  setAcceptTerms={setAcceptTerms}
                />
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Grid container spacing={20} columns={8} marginBottom={8}>
                <Grid item xs={4}>
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate(path, { replace: true })}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={isPasswordAcceptAllCriteria() && acceptTerms ? 'primary' : 'inherit'}
                    disabled={!(isPasswordAcceptAllCriteria() && acceptTerms)}
                    loading={loading}
                  >
                    Enviar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
      </ThemeProvider>
    </>
  );
}
