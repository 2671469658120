import { MenuItem } from '@mui/material';
import VMasker from 'vanilla-masker';
import { TableOption } from '../../../../../shared/components/data-grip-table/option-item';

const actions = (handleUnlinkTechnician) => [
  {
    render: (row, key) => (
      <MenuItem onClick={() => handleUnlinkTechnician(row.id)} key={key}>
        Desvincular
      </MenuItem>
    )
  }
];
export const columns = (handleUnlinkTechnician) => [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    editable: true,
    sortable: true,
    valueGetter: ({ row }) => `${row?.firstName} ${row?.lastName}`
  },
  {
    field: 'email',
    headerName: 'E-mail',
    minWidth: 200,
    flex: 1,
    editable: true,
    sortable: true
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    minWidth: 200,
    flex: 1,
    editable: true,
    sortable: true,
    valueGetter: ({ row }) => (row?.phone ? VMasker.toPattern(row.phone, '(99) 9 9999-9999') : '')
  },
  {
    field: 'ações',
    headerName: 'Ações',
    disableColumnMenu: true,
    maxWidth: 100,
    flex: 1,
    renderCell: ({ row }) => TableOption({ options: actions(handleUnlinkTechnician), row })
  }
];
