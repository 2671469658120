import * as api from './api';

export const create = async (user) => {
  try {
    const response = await api.post('user', user);

    return response;
  } catch {
    return { statusCode: 500 };
  }
};

export const findAll = async (
  { firstName, lastName, document, active, pending, mainUser },
  page,
  pageSize
) => {
  try {
    let path = 'user';

    if (firstName) {
      path = path.concat(
        path.includes('?') ? `&firstName=${firstName}` : `?firstName=${firstName}`
      );
    }
    if (lastName) {
      path = path.concat(path.includes('?') ? `&lastName=${lastName}` : `?lastName=${lastName}`);
    }

    if (mainUser) {
      path = path.concat(path.includes('?') ? `&mainUser=${mainUser}` : `?mainUser=${mainUser}`);
    }

    if (document) {
      path = path.concat(path.includes('?') ? `&document=${document}` : `?document=${document}`);
    }
    if (active) {
      if (active === 'active') {
        path = path.concat(path.includes('?') ? `&active=active` : `?active=active`);
      } else if (active === 'disabled') {
        path = path.concat(path.includes('?') ? `&active=disabled` : `?active=disabled`);
      }
    }
    if (pending) {
      if (pending === 'pending') {
        path = path.concat(path.includes('?') ? `&pending=pending` : `?pending=pending`);
      } else if (pending === 'notPending') {
        path = path.concat(path.includes('?') ? `&pending=notPending` : `?pending=notPending`);
      }
    }
    if (page) {
      path = path.concat(path.includes('?') ? `&page=${page}` : `?page=${page}`);
    }
    if (pageSize) {
      path = path.concat(path.includes('?') ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`);
    }

    const { data, count } = await api.get(path);

    return { data, count };
  } catch {
    return { statusCode: 500 };
  }
};

export const findByDocument = async (document) => {
  try {
    const { data } = await api.get(`user/list/${document}`);

    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const findById = async (id) => {
  try {
    const data = await api.get(`user/${id}`);

    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const updateUser = async (id, body) => {
  try {
    const data = await api.patch(`user/${id}`, body);

    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const deleteUser = async (id) => {
  try {
    const data = await api.del(`user/${id}`);

    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const sotfDeleteUser = async (id) => {
  try {
    const data = await api.post(`user/soft-delete/${id}`);

    return data;
  } catch {
    return { statusCode: 500 };
  }
};
