import React from 'react';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';

const BillingFileInput = ({ value, index, onChange, files, disabled, ...props }) => {
  const handleChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const updatedFiles = [...files];

    for (let i = 0; i < selectedFiles.length; i++) {
      const fileExists = files.some((file) => file.name === selectedFiles[i].name);

      if (!fileExists) {
        updatedFiles.push(selectedFiles[i]);
      }
    }

    document.getElementById(`contained-button-${index}-file`).value = '';

    onChange(updatedFiles);
  };

  return (
    <Box>
      <label htmlFor={`contained-button-${index}-file`}>
        <input
          disabled={disabled}
          style={{ display: 'none' }}
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id={`contained-button-${index}-file`}
          multiple
          onChange={handleChange}
          type="file"
          {...props}
        />

        <Button
          disabled={disabled}
          component="span"
          variant="contained"
          color="primary"
          endIcon={<Add />}
          sx={{ width: '200px', height: '40px' }}
        >
          Adicionar arquivo
        </Button>
      </label>
    </Box>
  );
};

export default BillingFileInput;
