import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { Box, Container } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

import { Page } from '../../../../components';
import DataGridMain from '../../../../components/DataGridMain/DataGridMain';
import { useAuth } from '../../../../contexts';
import { internalLogs, user } from '../../../../services';

export default function UserList() {
  const FILTERS_INITIAL_STATE = {
    firstName: '',
    lastName: '',
    document: '',
    active: '',
    pending: ''
  };

  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(0);
  const [info, setInfo] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const { profile } = useAuth();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await makeFilters();
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao recuperar usuários!');
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, page, pageSize]);

  const handleChangeFilters = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'Nome',
      minWidth: 150,
      flex: 1,
      editable: false
    },
    {
      field: 'lastName',
      headerName: 'Sobrenome',
      minWidth: 150,
      flex: 1,
      editable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      maxWidth: 150,
      flex: 1,
      editable: false
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      minWidth: 100,
      flex: 1,
      editable: false
    },
    {
      field: 'document',
      headerName: 'Documento',
      minWidth: 150,
      flex: 1,
      editable: false
    },
    {
      field: 'active',
      headerName: 'Ativação',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: ({ row }) => (row.active === true ? 'Habilitado' : 'Bloqueado')
    },
    {
      field: 'pending',
      headerName: 'Pendencia',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: ({ row }) => (row.pending === true ? 'Pendente' : 'Não pendente')
    },
    {
      headerName: 'Ação',
      minWidth: 150,
      flex: 1,
      editable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <Link to={`/user/${row.id}`} key={row.id} style={{ textDecoration: 'none' }}>
          <GridActionsCellItem
            icon={<EditIcon />}
            disabled={!profile.role.includes('admin')}
            label="Edit"
          />
        </Link>,
        <GridActionsCellItem
          key={row.id}
          disabled={!profile.role.includes('admin')}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => {
            handleConfirm(row);
          }}
          color="error"
        />
      ]
    }
  ];

  const sendLog = async (email) => {
    const notify = {
      userId: profile.id,
      name: profile.name,
      companyName: profile.company,
      companyDocument: profile.document,
      action: 'deleted user',
      protocol: 'no protocol',
      description: `O usuário ${profile.name.toUpperCase()}, da empresa ${profile.company.toUpperCase()}, deletou o usuario de e-mail : ${email}`
    };

    await internalLogs.create(notify);
  };

  const handleDelete = async (data) => {
    try {
      await user.sotfDeleteUser(data.id);
      await sendLog(data.email);
      toast.success('Usuário deletado com sucesso!');
      setInfo(null);
    } catch (error) {
      setInfo('Houve algum erro!');
    }
  };

  const handleConfirm = (row) => {
    setInfo(
      <>
        <DialogContentText>{`Gostaria de Desativar o usuário ${`${row.firstName} ${row.lastName}`}?`}</DialogContentText>
        <DialogActions>
          <Button autoFocus onClick={() => setInfo(null)} variant="contained" color="primary">
            Cancelar
          </Button>
          <Button autoFocus onClick={() => handleDelete(row)} variant="outlined" color="error">
            Excluir
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderDataGrid = (data) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '80vh',
        width: '100%'
      }}
    >
      <DataGridMain
        gridRows={data}
        gridColumns={columns}
        rowCount={rowCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </Box>
  );

  const makeFilters = async () => {
    const { data, count } = await user.findAll(filters, page, pageSize);
    setUsers(data);
    setRowCount(count);
  };

  const handleFilterUsers = async () => {
    try {
      await makeFilters();
    } catch {
      toast.error('Erro ao filtrar usuários');
    }
  };

  const handleClearFilters = () => {
    setFilters(FILTERS_INITIAL_STATE);
  };

  const handleResetFilters = async () => {
    try {
      setLoading(true);

      handleClearFilters();
      await makeFilters();

      setLoading(false);
    } catch {
      toast.error('Erro ao resetar pesquisa');
    }
  };

  return (
    <Page title="Usuário" loading={loading}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Paper
              sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              elevation={3}
            >
              <Grid>
                <Typography variant="h4" sx={{ color: '#454F5B' }}>
                  Lista de Usuários
                </Typography>
                <Grid container spacing={2} marginTop={1} justifyContent="space-between">
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label="Nome"
                      fullWidth
                      value={filters.firstName}
                      type="text"
                      size="small"
                      onChange={handleChangeFilters}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label="Sobrenome"
                      fullWidth
                      value={filters.lastName}
                      type="name"
                      size="small"
                      onChange={handleChangeFilters}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="document"
                      label="CPF/CNPJ"
                      fullWidth
                      value={filters.document}
                      type="text"
                      size="small"
                      onChange={handleChangeFilters}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="active"
                      label="Ativação"
                      fullWidth
                      value={filters.active}
                      select
                      size="small"
                      onChange={handleChangeFilters}
                    >
                      <MenuItem key="active" value="active">
                        Habilitado
                      </MenuItem>
                      <MenuItem key="disabled" value="disabled">
                        Bloqueado
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="pending"
                      label="Pendencia"
                      fullWidth
                      value={filters.pending}
                      select
                      size="small"
                      onChange={handleChangeFilters}
                    >
                      <MenuItem key="pending" value="pending">
                        Pendente
                      </MenuItem>
                      <MenuItem key="notPending" value="notPending">
                        Não pendente
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid padding={3} container spacing={2} marginTop={0}>
                    <Grid margin={1}>
                      <Button variant="contained" color="primary" onClick={handleFilterUsers}>
                        Pesquisar
                      </Button>
                    </Grid>
                    <Grid margin={1}>
                      <Button variant="outlined" color="primary" onClick={handleClearFilters}>
                        Limpar filtros
                      </Button>
                    </Grid>
                    <Grid margin={1}>
                      <Button variant="outlined" color="primary" onClick={handleResetFilters}>
                        Resetar filtros
                      </Button>
                    </Grid>
                  </Grid>
                  {renderDataGrid(users)}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={info !== null}
          onClose={() => setInfo(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
          <DialogContent>{info} </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}
