import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FilesTypes } from '../../shared/constants';

const Input = styled('input')({
  display: 'none'
});

const FileInput = ({ value: file, onChange = () => {}, onDelete, origin, ...props }) => {
  const [files, setFiles] = useState(file || []);

  useEffect(() => {
    onChange(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <Box
      sx={{
        p: 1,
        border: '1px solid',
        borderRadius: 1,
        borderColor: 'divider',
        width: '20%',
        minWidth: 300,
        height: '40%',
        minHeight: 300
      }}
    >
      <label htmlFor={`contained-button-${origin}-file`}>
        <Input
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id={`contained-button-${origin}-file`}
          multiple
          onChange={({ target }) => {
            setFiles((e) => [...e, ...target.files]);
          }}
          type="file"
          {...props}
        />

        <Button component="span" size="small" variant="contained" sx={{ width: '100%' }}>
          {FilesTypes[origin]}
        </Button>
        <List sx={{ width: '100%' }}>
          {files.map(({ name }, i) => (
            <ListItem key={i}>
              <ListItemText secondary={name} />
              <Button
                onClick={() => {
                  setFiles(files.filter((_, index) => index !== i));
                  onDelete(i);
                }}
              >
                <DeleteIcon />
              </Button>
            </ListItem>
          ))}
        </List>
      </label>
    </Box>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.array,
  origin: PropTypes.string
};

export default FileInput;
