import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  TextField
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { billing } from 'src/services';
import { useBillingsToSaveStore, verifyEditFields, verifySendFields } from 'src/services/store';
import { billingOptions, ticketLevelOptions } from '../../options';
import { formFields } from './formField';
import {
  BillingFileButton,
  BillingInputContainer,
  CancelButton,
  NewBillingFormControl,
  NewBillingInput,
  NewBillingSelect
} from './style';
import { translateBillFileType, translateBillType } from './translate';

export default function HandleEditFile(props) {
  const {
    billType,
    removeInput,
    billIndex,
    inputIndex,
    billingFilesData,
    clientDocument,
    isNewFile,
    formType
  } = props;
  const [limitIdentificationDate, setLimitIdentificationDate] = useState(null);
  const [filePreviewName, setFilePreviewName] = useState(null);
  const [notificationInputs, setNotificationInputs] = useState(false);
  const [open, setOpen] = useState(false);
  const [filePreview, setFilePreview] = useState('');
  const [plate, setPlate] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [ait, setAit] = useState(null);
  const [ticketLevel, setTicketLevel] = useState(null);
  const [vehiclesList, setVehiclesList] = useState([]);
  const billingsToEditAndSend = useBillingsToSaveStore((state) => state.billingsToSave);
  const options = billingOptions[billType];

  useEffect(() => {
    const loadPlates = async () => {
      const vehiclesList = [];
      setVehiclesList(vehiclesList.map((vehicle) => vehicle.plate));
    };
    loadPlates();
    if (billingFilesData) {
      setFileType(billingFilesData.fileType);
      const fileName = translateBillType[billType];
      if (billingFilesData.fileType) {
        const fileSecondName = translateBillFileType[billingFilesData.fileType];
        setFilePreviewName(`${fileName} -  ${fileSecondName}`);
      } else {
        setFilePreviewName(`${fileName}`);
      }
      if (billingFilesData.ticketInfo) {
        setNotificationInputs(true);
        setPlate(billingFilesData.ticketInfo.vehicle.plate);
        setAit(billingFilesData.ticketInfo.ait);
        setTicketLevel(billingFilesData.ticketInfo.ticketLevel);
        setLimitIdentificationDate(billingFilesData.ticketInfo.limitIdentificationDate);
      }
    }
  }, []);

  const handleFilePreview = async (index) => {
    setOpen(true);
    const { key } = billingFilesData;
    const { fileURL, pdf } = await billing.getDoc(key);
    setFilePreview(fileURL);
  };

  const handleNewFilePreview = async (index) => {
    setOpen(true);
    billingsToEditAndSend.forEach((billing) => {
      if (billing.id === billIndex) {
        const { file } = billing.newFiles[index];
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleFieldsChange = (index, value, field) => {
    if (isNewFile) {
      const update = billingsToEditAndSend;
      switch (field) {
        case formFields.fileType:
          setFileType(value);
          update.forEach((billing) => {
            if (billing.id === billIndex) {
              billing.newFiles[index][field] = value;
            }
          });
          if (value === 'infringement' || value === 'notification' || value === 'nic') {
            setNotificationInputs(true);
            update.forEach((billing) => {
              if (billing.id === billIndex) {
                billing.newFiles[index].ticketInfo = {
                  vehiclePlate: plate,
                  ait,
                  ticketLevel,
                  limitIdentificationDate
                };
              }
            });

            useBillingsToSaveStore.setState({ billingsToSave: update });

            const isDisabled = verifyEditFields(update);
            useBillingsToSaveStore.setState({ disableSave: isDisabled });
          } else {
            update.forEach((billing) => {
              if (billing.id === billIndex) {
                delete billing.newFiles[index].ticketInfo;
              }
            });
            useBillingsToSaveStore.setState({ billingsToSave: update });
            setNotificationInputs(false);
          }
          break;
        case formFields.vehiclePlate:
          setPlate(value);
          update.forEach((billing) => {
            if (billing.id === billIndex) {
              billing.newFiles[index].ticketInfo.vehiclePlate = value;
            }
          });
          break;
        case formFields.ait:
          setAit(value);
          update.forEach((billing) => {
            if (billing.id === billIndex) {
              billing.newFiles[index].ticketInfo.ait = value;
            }
          });
          break;
        case formFields.ticketLevel:
          setTicketLevel(value);
          update.forEach((billing) => {
            if (billing.id === billIndex) {
              billing.newFiles[index].ticketInfo.ticketLevel = value;
            }
          });
          break;
        case formFields.limitIdentificationDate:
          setLimitIdentificationDate(value);
          update.forEach((billing) => {
            if (billing.id === billIndex) {
              billing.newFiles[index].ticketInfo.limitIdentificationDate = value;
            }
          });
          break;
        default:
          break;
      }
    } else {
      billingsToEditAndSend.forEach((billing) => {
        if (billing.id === billIndex) {
          switch (field) {
            case formFields.fileType:
              setFileType(value);
              if (value === 'billet' || value === 'deposit') {
                setNotificationInputs(false);
                billing.bill.forEach((file) => {
                  if (file.key === index) {
                    delete file.ticketInfo;
                  }
                });
              } else {
                setNotificationInputs(true);
                billing.bill.forEach((file) => {
                  if (file.key === index) {
                    file.ticketInfo = {
                      vehiclePlate: plate,
                      ait,
                      ticketLevel,
                      limitIdentificationDate
                    };
                  }
                });
              }
              billing.bill.forEach((file) => {
                if (file.key === index) {
                  file.fileType = value;
                }
              });
              break;
            case formFields.vehiclePlate:
              setPlate(value);
              billing.bill.forEach((file) => {
                if (file.key === index) {
                  if (!file.ticketInfo) {
                    file.ticketInfo = {
                      vehiclePlate: value
                    };
                  } else {
                    file.ticketInfo.vehiclePlate = value;
                  }
                }
              });
              break;
            case formFields.ait:
              setAit(value);
              billing.bill.forEach((file) => {
                if (file.key === index) {
                  if (!file.ticketInfo) {
                    file.ticketInfo = {
                      ait: value
                    };
                  } else {
                    file.ticketInfo.ait = value;
                  }
                }
              });
              break;
            case formFields.ticketLevel:
              setTicketLevel(value);
              billing.bill.forEach((file) => {
                if (file.key === index) {
                  if (!file.ticketInfo) {
                    file.ticketInfo = {
                      ticketLevel: value
                    };
                  } else {
                    file.ticketInfo.ticketLevel = value;
                  }
                }
              });
              break;
            case formFields.limitIdentificationDate:
              setLimitIdentificationDate(value);
              billing.bill.forEach((file) => {
                if (file.key === index) {
                  if (!file.ticketInfo) {
                    file.ticketInfo = {
                      limitIdentificationDate: value
                    };
                  } else {
                    file.ticketInfo.limitIdentificationDate = value;
                  }
                }
              });
              break;
          }
        }
      });
    }

    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToEditAndSend);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyEditFields(billingsToEditAndSend);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
  };

  const handleChangeAutoComplete = (event, newValue) => {
    if (typeof newValue === 'string') {
      handleFieldsChange(inputIndex, newValue, 'vehiclePlate');
    } else if (newValue && newValue.inputValue) {
      handleFieldsChange(inputIndex, newValue.inputValue, 'vehiclePlate');
    } else {
      handleFieldsChange(inputIndex, newValue, 'vehiclePlate');
    }
  };

  const handleDialogClose = () => {
    setFilePreview(null);
    setOpen(false);
  };

  return (
    <>
      <BillingInputContainer>
        {isNewFile ? (
          <BillingFileButton
            startIcon={<DescriptionIcon />}
            onClick={() => handleNewFilePreview(inputIndex)}
          >
            {filePreviewName}
          </BillingFileButton>
        ) : (
          <BillingFileButton
            startIcon={<DescriptionIcon />}
            onClick={() => handleFilePreview(inputIndex)}
          >
            {filePreviewName}
          </BillingFileButton>
        )}
        <NewBillingFormControl>
          <InputLabel shrink id="bill-types">
            Tipo de arquivo
          </InputLabel>
          <NewBillingSelect
            labelId="bill-types"
            label="Tipo de arquivo"
            value={fileType}
            onChange={(e) => handleFieldsChange(inputIndex, e.target.value, 'fileType')}
          >
            {options &&
              options.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
          </NewBillingSelect>
        </NewBillingFormControl>
        {notificationInputs ? (
          <>
            <Autocomplete
              selectOnFocus
              clearOnBlur
              freeSolo
              handleHomeEndKeys
              options={vehiclesList}
              value={plate}
              sx={{ width: 300 }}
              onChange={(event, newValue) => handleChangeAutoComplete(event, newValue)}
              renderInput={(params) => <TextField {...params} label="Placa do Veículo" />}
            />
            <NewBillingInput
              type="text"
              onChange={(e) => handleFieldsChange(inputIndex, e.target.value, 'ait')}
              placeholder="AIT"
              label="AIT"
              value={ait}
            />
            <NewBillingFormControl>
              <InputLabel id="ticket-level">Gravidade</InputLabel>
              <NewBillingSelect
                labelId="ticket-level"
                label="Gravidade da Multa"
                value={ticketLevel}
                onChange={(e) => handleFieldsChange(inputIndex, e.target.value, 'ticketLevel')}
              >
                {ticketLevelOptions.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.key}
                  </MenuItem>
                ))}
              </NewBillingSelect>
            </NewBillingFormControl>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={limitIdentificationDate}
                label="Data limite para identificação"
                onChange={(value) => {
                  handleFieldsChange(inputIndex, value, 'limitIdentificationDate');
                }}
                renderInput={(params) => (
                  <NewBillingInput
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Data limite para identificação'
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        ) : (
          <></>
        )}
        <CancelButton color="error" variant="contained" onClick={() => removeInput(inputIndex)}>
          <CloseIcon />
        </CancelButton>
      </BillingInputContainer>
      <Dialog
        open={open}
        onClose={() => handleDialogClose()}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Preview de {filePreviewName}</DialogTitle>
        <DialogContent
          sx={{
            height: '700px'
          }}
        >
          {filePreview && (
            <iframe src={filePreview} title={filePreviewName} height="100%" width="100%" />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

HandleEditFile.propTypes = {
  billType: PropTypes.string,
  removeInput: PropTypes.func,
  inputIndex: PropTypes.string,
  billIndex: PropTypes.string,
  vehiclesList: PropTypes.array,
  billingFilesData: PropTypes.object,
  clientDocument: PropTypes.string,
  isNewFile: PropTypes.bool,
  formType: PropTypes.string
};
