import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BillingFileInput from 'src/components/BillingFileInput';
import { billing } from '../../../../../services';

export default function Inputs({ data, reload }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [dueDate, setDueDate] = useState(data.dueDate);

  const { origin } = data.bill[0];
  const index = 1;

  const sendFiles = async (files, origin) => billing.sendFiles(files, 'other', origin);

  const handleChange = async (docs) => {
    setFiles(docs);
  };

  const handleUpload = async (docs) => {
    const billSent = await sendFiles(docs, origin);
    const removeUndefined = billSent.filter((e) => e !== undefined);
    return removeUndefined.flatMap((e) => e);
  };

  const handleEdit = async () => {
    setLoading(true);
    const billFiles = await handleUpload(files);
    const bill = [...data.bill, ...billFiles];
    const doc = await billing.update(data.id, bill);

    if (!doc) {
      setLoading(false);
      return setMessage('Algo deu errado, refaça a operação');
    }

    setLoading(false);
    return setMessage('Documentos adicionados com sucesso');
  };

  const handleDueDate = async () => {
    setLoading(true);
    await billing.update(data.id, [], dueDate);
    setLoading(false);
  };

  return (
    <Paper
      sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
      elevation={3}
    >
      <Typography variant="h5" sx={{ p: 2 }}>
        Alterar data do vencimento
      </Typography>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Box>
          <DesktopDatePicker
            label="Data de vencimento"
            inputFormat="dd/MM/yyyy"
            value={dueDate}
            onChange={(value) => {
              setDueDate(value);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Button onClick={handleDueDate} variant="contained" sx={{ marginTop: '1rem' }}>
          Salvar nova data
        </Button>
      </LocalizationProvider>
      <Typography variant="h5" sx={{ p: 2 }}>
        Adicionar novos documentos
      </Typography>
      <Box
        sx={{
          margin: '10px 0',
          display: 'flex',
          width: '50%',
          height: 'auto',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          {files.map((file, i) => (
            <ListItem
              key={i}
              sx={{
                width: 'auto',
                bgcolor: '#F2F4F7',
                borderRadius: '8px',
                p: '4px',
                height: '24px',
                mr: '10px',
                mb: '10px'
              }}
            >
              <ListItemText secondary={file.name} />
              <button
                style={{
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '5px',
                  border: 'none',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  const updatedFiles = [...files];
                  const newFiles = updatedFiles.filter((e) => file !== e);
                  setFiles(newFiles);
                }}
              >
                <CloseOutlinedIcon
                  sx={{
                    width: '15px',
                    height: '15px',
                    fontSize: 'small',
                    color: '#667085'
                  }}
                />
              </button>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <BillingFileInput
          onChange={(docs) => {
            handleChange(docs);
          }}
          origin={origin}
          index={index}
          value={files}
          files={files}
          disabled={false}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '200px', ml: '20px', height: '40px' }}
          disabled={files.length < 1}
          onClick={handleEdit}
        >
          Salvar arquivos
        </Button>
      </Box>
      <Dialog
        open={message !== null}
        onClose={() => reload()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fatura</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={loading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CircularProgress />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Paper>
  );
}

Inputs.propTypes = {
  data: PropTypes.object,
  reload: PropTypes.func
};
