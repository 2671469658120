import React from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom';

import { Box, Stack, Toolbar } from '@mui/material';

import { Logo } from '../components';
import { Navbar, Notification } from './components';

export default function SimpleLayout() {
  return (
    <>
      <Navbar position="absolute">
        <Toolbar
          sx={{
            pr: '24px'
          }}
        >
          <Box component={RouterLink} to="/auth" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Notification />
          </Stack>
        </Toolbar>
      </Navbar>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: '#f9f9f9'
        }}
      >
        <Toolbar />
        <Box sx={{ style: 'flex', p: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
