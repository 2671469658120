import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';

import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { Page } from '../../components';
import DataGridMain from '../../components/DataGridMain/DataGridMain';
import { useAuth } from '../../contexts';
import { customers, user } from '../../services';
import { TableOption } from '../../shared/components/data-grip-table/option-item';
import { ClienteStatus } from '../../shared/constants';

export default function Clients() {
  const { refresh } = useAuth();

  const FILTERS_INITIAL_STATE = {
    firstName: '',
    lastName: '',
    document: '',
    active: '',
    pending: ''
  };

  const [data, setData] = useState([]);
  const [client, setClient] = useState([]);
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState({ cnpj: '', status: '', user: '' });
  const [keyClient, setKeyClient] = useState(0);
  const [keyUser, setKeyUser] = useState(100);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);
  const [rowCount, setRowCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [deleted, setDeleted] = useState(0);
  const [showUsers, setShowUsers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const value = await customers.getCustomersTotvs();
        if (value) {
          setData(value);
          setClient(value);
          setClients(value.map((e) => `${e.name} - ${e.document}`));
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await makeFilters();
        setLoading(false);
      } catch (error) {
        console.error('Erro ao recuperar usuários!');
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, page, pageSize]);

  const makeFilters = async () => {
    const { data, count } = await user.findAll(filters, page);
    setUsers(data);
    setShowUsers(data.map((e) => `${e.firstName} ${e.lastName} - ${e?.email}`));
    setRowCount(count);
  };

  useEffect(() => {
    if (filter?.cnpj === '' && filter?.status === '' && filter?.user === '') {
      setClient(data);
    } else {
      const newFilter = data
        .filter((data) => {
          if (filter?.cnpj !== '' || filter?.cnpj === null) {
            if (data?.document === filter?.cnpj) {
              return data;
            }
            return false;
          }
          return true;
        })
        .filter((data) => {
          if (filter?.status === 'ATIVO') {
            if (data.active) {
              return true;
            }
            return false;
          }
          if (filter?.status === 'INATIVO') {
            if (!data.active) {
              return true;
            }
            return false;
          }
          return true;
        })
        .filter((data) => {
          if (Array.isArray(filter.user)) {
            for (let i = 0; i < filter.user.length; i++) {
              if ((data?.document === filter.user[i] && data?.document !== null) || user[i]) {
                return data;
              }
            }
            return false;
          }
          if (filter.user !== '' || filter.user === null) {
            if (
              (data?.document === filter.user && data?.document !== null) ||
              data?.document === user
            ) {
              return data;
            }
            return false;
          }
          return true;
        });
      setClient(newFilter);
    }
  }, [filter]);

  function isAtive(active) {
    if (active) {
      return 'Ativo';
    }
    return 'Inativo';
  }

  function isPhoneOrIsCell(document) {
    for (let i = 0; i < users.length; i++) {
      if (Array.isArray(users[i]?.document)) {
        for (let j = 0; j < users[i].document.length; j++) {
          if (users[i].document[j] === document && users[i]?.mainUser) {
            return users[i].phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '$1 $2 $3 $4');
          }
        }
      } else if (users[i].document === document && users[i].mainUser) {
        return users[i].phone;
      }
    }
    return 'Defina o contato principal';
  }

  function formatDocument(document) {
    if (document.length === 11)
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  const resetFilter = async () => {
    setClient(data);
    setFilter({ cnpj: '', status: '', user: '' });
    setKeyClient((keyClient) => keyClient + 1);
    setKeyUser((keyUser) => keyUser + 1);
  };

  const getDocument = (value) => {
    const regex = /(\d{2}[.]?\d{3}[.]?\d{3}[/]?\d{4}[-]?\d{2})|(\d{3}[.]?\d{3}[.]?\d{3}[-]?\d{2})/;
    const doc = value.match(regex);
    return doc ? doc[0] : null;
  };

  const actions = [
    {
      render: (row) => (
        <MenuItem onClick={() => navigate(`/manager/client/details/${row.document}`)}>
          <VisibilityIcon sx={{ marginRight: '8px' }} /> Visualizar
        </MenuItem>
      )
    }
  ];
  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      minWidth: 200,
      flex: 1,
      editable: false
    },
    {
      field: 'document',
      headerName: 'CNPJ/CPF',
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        const { document } = row;
        return <Typography>{formatDocument(document)}</Typography>;
      }
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => {
        const { document } = row;
        return <Typography>{isPhoneOrIsCell(document)} </Typography>;
      }
    },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => {
        const { active } = row;
        return <Typography> {isAtive(active)} </Typography>;
      }
    },
    {
      field: 'ações',
      headerName: 'Ações',
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      renderCell: ({ row }) => TableOption({ options: actions, row })
    }
  ];
  return (
    <Page title="Clientes Seteloc" loading={loading}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Paper elevation={3} sx={{ p: 2, marginBottom: 1, height: '104px', borderRadius: '8px' }}>
            <Typography variant="h4">Administração de Clientes</Typography>
            <hr
              style={{
                border: 0,
                borderTop: '2px solid #E77E22',
                marginTop: '1rem',
                marginBottom: '2rem'
              }}
            />
          </Paper>
          <Paper elevation={3} width="100%" borderRadius={2}>
            <LocalizationProvider dateAdapter={DateAdapter} adapterLocale={ptBR}>
              <Stack alignItems="justify" spacing={2} paddingX={1} paddingY={4}>
                <Stack spacing={2} direction="row">
                  <Autocomplete
                    id="Auto-client"
                    key={keyClient}
                    placeholder="Digite o cliente"
                    sx={{ width: '20rem' }}
                    renderInput={(row) => <TextField {...row} label="Cliente" />}
                    options={clients}
                    onInputChange={(e, value) => {
                      const cnpj = getDocument(value);
                      if (cnpj) {
                        setFilter({ ...filter, cnpj });
                      } else if (value === '') {
                        setFilter({ cnpj: '', status: '', user: '' });
                      }
                    }}
                  />
                  <FormControl sx={{ width: '20rem' }}>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      value={filter?.status}
                      onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                      label="Status"
                    >
                      <MenuItem value={ClienteStatus.ACTIVE}>Ativo</MenuItem>
                      <MenuItem value={ClienteStatus.NOT_ACTIVE}>Inativo</MenuItem>
                    </Select>
                  </FormControl>
                  <Autocomplete
                    id="Auto-user"
                    key={keyUser}
                    placeholder="Digite o usuário"
                    sx={{ width: '20rem' }}
                    renderInput={(row) => <TextField {...row} label="Usuários" />}
                    options={showUsers}
                    onInputChange={(e, value) => {
                      const email = value.split('- ');
                      for (let i = 0; i < users.length; i++) {
                        if (users[i].email === email[1]) {
                          if (Array.isArray(users[i]?.document)) {
                            setFilter({ ...filter, user: users[i].document });
                          } else {
                            setFilter({ ...filter, user: users[i]?.document });
                          }
                        }
                      }
                      if (value === '') {
                        setFilter({ cnpj: '', status: '', user: '' });
                      }
                    }}
                  />
                  <Button variant="contained" onClick={() => resetFilter()}>
                    Limpar filtros
                  </Button>
                </Stack>
              </Stack>
            </LocalizationProvider>
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              height: '70vh',
              width: '100%'
            }}
            elevation={3}
          >
            <DataGridMain
              gridRows={client}
              gridColumns={columns}
              setPage={setPage}
              page={page}
              getRowId={(row) => row.document}
            />
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}
