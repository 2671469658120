import React, { useEffect, useState } from 'react';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';

import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Paper,
  Grid,
  Typography,
  Container
} from '@mui/material';
import { Visibility, SaveAlt } from '@mui/icons-material';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import DataGridMain from '../../components/DataGridMain/DataGridMain';
import { api, billing, internalLogs, sheet } from '../../services';
import { Page } from '../../components';
import { Visualize } from './components';
import { useAuth } from '../../contexts';
import { columns as tableColumns } from './table-columns';
import { BillingViewStatus } from '../../shared/constants';

export default function Bill() {
  const { profile } = useAuth();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [loading, setLoading] = useState(false);
  const [visualize, setVisualize] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await billing.getBilling({ client: profile.document });
        if (data) setData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };
    load();
  }, []);

  const columns = [
    ...tableColumns,
    {
      field: 'send',
      headerName: 'Ação',
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          sx={{ p: 1 }}
          color="info"
          endIcon={<Visibility />}
          onClick={() => sendData(row)}
          id="view-bill"
        >
          Visualizar
        </Button>
      )
    }
  ];

  const sendLog = async (row) => {
    const notify = {
      userId: profile.id,
      name: profile.name,
      companyName: profile.company,
      companyDocument: profile.document,
      action: 'billing vizualized',
      protocol: row.protocol,
      description: `O usuário ${profile.name.toUpperCase()}, da empresa ${profile.company.toUpperCase()}, vizualizou a fatura de número ${
        row.protocol
      }`
    };

    const send = profile?.role.includes('manager') ? await internalLogs.create(notify) : null;
    return send;
  };

  async function sendData(row) {
    setVisualize(row);
    sendLog(row);

    try {
      const res = await api.post('billing/visualized', {
        userId: profile.id,
        userName: profile.name,
        userRoles: profile.role,
        billingId: row.id
      });

      if (res.statusCode !== 200) throw new Error(res.message);

      if (!row.lastVisualized) {
        setData(
          data.map((bill) => {
            if (bill.id === row.id) {
              return { ...bill, lastVisualized: {} };
            }
            return bill;
          })
        );
        if (filteredData) {
          setFilteredData(
            filteredData.map((bill) => {
              if (bill.id === row.id) {
                return { ...bill, lastVisualized: {} };
              }
              return bill;
            })
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const handleSearch = () => {
    if (!start && !end && !status) {
      toast.warning('Preencha pelo menos um parâmetro para pesquisar');
    }
    setLoading(true);

    const newData = data
      .filter((bill) => {
        const isCreatedAfterSearchStartDate = start
          ? new Date(bill.createdAt).getTime() >= new Date(start.setUTCHours(0, 0, 0, 0)).getTime()
          : false;
        const isBeforeAfterSearchStartDate = end
          ? new Date(bill.createdAt).getTime() <=
            new Date(end.setUTCHours(23, 59, 59, 999)).getTime()
          : false;
        if (start && end) return isCreatedAfterSearchStartDate && isBeforeAfterSearchStartDate;
        if (start) return isCreatedAfterSearchStartDate;
        if (end) return isBeforeAfterSearchStartDate;
        return true;
      })
      .filter((bill) => {
        if (status === BillingViewStatus.VISUALIZED) {
          if (bill.lastVisualized) {
            return true;
          }
          return false;
        }
        if (status === BillingViewStatus.NOT_VISUALIZED) {
          if (!bill.lastVisualized) {
            return true;
          }
          return false;
        }
        return true;
      });

    setLoading(false);
    return setFilteredData(newData);
  };

  const handleResetSearch = () => {
    setFilteredData(data);
    setStart(null);
    setEnd(null);
    setStatus('');
  };

  const handleExportData = async () => {
    try {
      const exportData = filteredData || data;
      if (exportData.length === 0) {
        toast.warning('Não há dados para exportar');
        return;
      }
      const formattedData = formatSheetData(exportData);
      await sheet.generateExcelSheet(formattedData);
    } catch (error) {
      toast.error('Ocorreu um erro ao exportar o relatório.');
    }
  };

  const formatSheetData = (data) => {
    try {
      return data.map((billData) => ({
        'Inserido em': new Date(billData?.createdAt).toLocaleDateString('pt-BR', {
          timeZone: 'America/Sao_Paulo'
        }),
        Protocolo: billData?.protocol,
        'Doc de Locação': billData?.bill.filter((e) => e.origin === 'rent').length,
        'Doc de KM Excedente': billData?.bill.filter((e) => e.origin === 'excess').length,
        'Doc de Multa': billData?.bill.filter((e) => e.origin === 'ticket').length,
        'Doc de Reembolso': billData?.bill.filter((e) => e.origin === 'refund').length
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title="Faturas" loading={loading}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item width="100%" height="100%">
            <LocalizationProvider dateAdapter={DateAdapter} adapterLocale={ptBR}>
              <Paper elevation={3}>
                <Grid item marginBottom={1}>
                  <Grid container justifyContent="space-between" paddingX={2} paddingTop={1}>
                    <Grid item>
                      <Typography variant="h3">Faturas</Typography>
                    </Grid>
                    <Grid item>
                      <Button size="large" onClick={handleExportData}>
                        <SaveAlt sx={{ marginRight: '.5rem' }} />
                        Exportar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Stack alignItems="center" spacing={2} paddingX={1} paddingY={3}>
                  <Stack spacing={2} direction="row">
                    <DesktopDatePicker
                      label="Data inicial"
                      inputFormat="dd/MM/yyyy"
                      maxDate={end}
                      value={start}
                      onChange={(value) => setStart(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/mm/aaaa'
                          }}
                        />
                      )}
                    />

                    <DesktopDatePicker
                      label="Data final"
                      inputFormat="dd/MM/yyyy"
                      minDate={start}
                      value={end}
                      onChange={(value) => setEnd(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/mm/aaaa'
                          }}
                        />
                      )}
                    />

                    <FormControl sx={{ width: '15rem' }}>
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        labelId="status"
                        id="status"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        <MenuItem value={BillingViewStatus.VISUALIZED}>Visualizado</MenuItem>
                        <MenuItem value={BillingViewStatus.NOT_VISUALIZED}>
                          Não visualizado
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <Button variant="contained" onClick={handleSearch}>
                      Pesquisar
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ marginLeft: '1rem' }}
                      onClick={handleResetSearch}
                    >
                      Limpar
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </LocalizationProvider>
          </Grid>
          <Grid item width="100%" height="100%">
            <Box
              sx={{
                height: '80vh',
                width: '100%'
              }}
            >
              <DataGridMain gridRows={filteredData || data} gridColumns={columns} />
            </Box>
            {visualize && (
              <Visualize
                open={visualize !== null}
                data={visualize}
                close={() => setVisualize(null)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
