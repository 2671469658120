import PropTypes from 'prop-types';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Redirect from './components/Redirect/redirect';
import { useAuth } from './contexts';
import { PrivateLayout, PublicLayout } from './layouts';
import SimpleLayout from './layouts/SimpleLayout';
import Forgot from './pages/Auth/Forgot';
import SignIn from './pages/Auth/SignIn';
import Bill from './pages/Bill';
import ClientRegister from './pages/ClientRegister';
import Clients from './pages/Clients';
import Company from './pages/Company';
import NotFound from './pages/Error/404';
import Billing from './pages/Manager/Billing';
import EditBilling from './pages/Manager/Billing/Edit';
import NewBilling from './pages/Manager/Billing/NewBilling';
import SendBilling from './pages/Manager/Billing/Send';
import Visualize from './pages/Manager/Billing/Visualize';
import LogsNotification from './pages/Manager/Logs';
import UpdateTechnician from './pages/Manager/UpdateTechnician';
import { UserEdit, UserList } from './pages/Manager/User';
import RegConfirmation from './pages/RegisterConfirmation';
import Settings from './pages/Settings/Password';

const RequireAuth = ({ children }) => {
  const { authorized } = useAuth();
  const location = useLocation();

  const hasRedirect = location?.pathname?.includes('redirect');

  if (!authorized) {
    return <Navigate to="/auth/sign-in" state={{ from: location.pathname, hasRedirect }} replace />;
  }

  return children;
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to="/clients" replace /> },
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        {
          path: 'company',
          element: (
            <RequireAuth>
              <Company />
            </RequireAuth>
          )
        }
      ]
    },
    {
      path: '/',
      element: <PrivateLayout />,
      children: [
        {
          path: 'redirect/*',
          element: (
            <RequireAuth>
              <Redirect path={window.location.pathname.replace('/redirect', '')} />
            </RequireAuth>
          )
        },
        {
          path: 'settings/password',
          element: (
            <RequireAuth>
              <Settings />
            </RequireAuth>
          )
        },
        {
          path: 'clients',
          element: (
            <RequireAuth>
              <Clients />
            </RequireAuth>
          )
        },
        {
          path: 'manager/client/details/:id',
          element: (
            <RequireAuth>
              <UpdateTechnician />
            </RequireAuth>
          )
        },
        {
          path: 'billing',
          element: (
            <RequireAuth>
              <Billing />
            </RequireAuth>
          )
        },
        {
          path: 'billing/:id',
          element: (
            <RequireAuth>
              <Visualize />
            </RequireAuth>
          )
        },
        {
          path: 'billing/sendBillings',
          element: (
            <RequireAuth>
              <SendBilling />
            </RequireAuth>
          )
        },
        {
          path: 'billing/edit',
          element: (
            <RequireAuth>
              <EditBilling />
            </RequireAuth>
          )
        },
        {
          path: 'billing/new',
          element: (
            <RequireAuth>
              <NewBilling />
            </RequireAuth>
          )
        },
        {
          path: 'bill',
          element: (
            <RequireAuth>
              <Bill />
            </RequireAuth>
          )
        },
        {
          path: 'user',
          element: (
            <RequireAuth>
              <UserList />
            </RequireAuth>
          )
        },
        {
          path: 'user/:id',
          element: (
            <RequireAuth>
              <UserEdit />
            </RequireAuth>
          )
        },
        {
          path: 'notification',
          element: (
            <RequireAuth>
              <LogsNotification />
            </RequireAuth>
          )
        }
      ]
    },
    {
      path: '/auth',
      element: <PublicLayout />,
      children: [
        { path: '/auth', element: <Navigate to="/auth/sign-in" replace /> },
        { path: 'sign-in', element: <SignIn /> },
        { path: 'forgot', element: <Forgot /> }
      ]
    },
    { path: 'sign-up', element: <ClientRegister /> },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: 'confirmation', element: <RegConfirmation /> }
  ]);
}

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired
};
