import { Box, Button, FormControl, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NewBillingContainer = styled(Box)({
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#FFF',
  gap: '10px',
  borderRadius: '8px'
});

export const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
});

export const NewBillingBox = styled(Box)({
  display: 'flex',
  gap: '24px'
});

export const NewBillingTitle = styled(Typography)({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '30px'
});

export const NewBillingFilesTitle = styled(Typography)({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '24px'
});

export const NewBillingFormControl = styled(FormControl)({
  width: '236px'
});

export const NewBillingInput = styled(TextField)({
  maxWidth: '236px',
  height: '50px',
  borderRadius: '50px'
});

export const NewBillingSelect = styled(Select)({
  maxWidth: '236px',
  height: '56px',
  borderRadius: '8px',
  alignItems: 'center'
});

export const FilesTypeSelect = styled(Select)({
  maxWidth: '236px',
  height: '44px',
  borderRadius: '8px',
  alignItems: 'center'
});

export const NewBillingButton = styled(Button)({
  borderRadius: '20px'
});

export const BillingFilesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
});

export const BillingInputContainer = styled(Box)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center'
});

export const BillingInput = styled('input')({
  display: 'none'
});

export const BillingFileButton = styled(Button)({
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #98A2B3',
  background: '#F2F4F7',
  cursor: 'pointer',
  color: '#344054',
  height: '56px',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px'
});

export const CancelButton = styled(Button)({
  height: '56px',
  width: '56px',
  borderRadius: '8px',
  background: '#F97066'
});

export const NoFilesText = styled(Typography)({
  color: '#98A2B3',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: '600',
  lineHeight: '24px'
});

export const EmailListContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '20px'
});

export const EmailItem = styled(Typography)({
  padding: '8px',
  background: '#F2F4F7',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  color: '#344054',
  borderRadius: '20px'
});
