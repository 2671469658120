import { Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '../../../components';
import { customers, user as userServices } from '../../../services';
import HeaderCompanyDetails from './components/Header';
import TechnicianSection from './components/TechnicianSection';
import UserSection from './components/UserSection';

export default function UpdateTechnician() {
  const [users, setUsers] = useState([]);
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const usersData = await userServices.findAll({ active: true });
      const client = await customers.findOneCustomerTotvs(id);
      setUsers(usersData.data);

      setClient(client);
      setLoading(false);
    })();
  }, [id]);

  return (
    <Page title="Detalhes da Empresa" loading={loading}>
      <Grid container direction="column" spacing={1}>
        <HeaderCompanyDetails client={client} />
        <Paper elevation={1} sx={{ m: 1, p: 2 }}>
          <UserSection document={client?.document} setLoading={setLoading} />
          <TechnicianSection
            technicians={client?.technicians}
            users={users}
            clientId={client?.id}
          />
        </Paper>
      </Grid>
    </Page>
  );
}
