import React, { useState } from 'react';
import { Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';

import { Validate, Change } from './components';

export default function Reset() {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({ phone: '', token: null, password: '' });

  const handleNext = async (value) => {
    setData(value);
    setActiveStep((activeStep) => activeStep + 1);
  };

  return (
    <>
      <Stack sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom>
          Recuperação de conta
        </Typography>
      </Stack>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ pt: 3, pb: 5 }}>
        <Step>
          <StepLabel>Número do telefone</StepLabel>
          <StepContent>
            <Validate data={data} next={handleNext} />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Código de segurança SMS</StepLabel>
          <StepContent>
            <Change data={data} next={handleNext} />
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
}
