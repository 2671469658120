import { Stack, Typography } from '@mui/material';

import { LoginForm } from './components';

export default function SignIn() {
  return (
    <>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="h3" gutterBottom>
          Painel do Gestor Admin
        </Typography>
        <Typography variant="h7" gutterBottom>
          Portal de uso exclusivo de colaboradores Seteloc S/A.
        </Typography>
      </Stack>

      <LoginForm />
    </>
  );
}
