import * as api from './api';

export const generateExcelSheet = async (dataArray) => {
  try {
    if (!dataArray) throw new Error('No data to export');
    const { data, mimetype } = await api.post('export-excel', dataArray);
    const file = new Blob([data], {
      type: mimetype
    });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', `relatorio.xlsx`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};
