import { Send as SendIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { billing } from '../../../../../services';

export default function Send({ data, open, close }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [list, setList] = useState([]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setList([...list, ...email.split(/;\s*/)]);
    setEmail('');
  };

  const handleSend = async () => {
    setLoading(true);
    const doc = await billing.consolidate(data.id, list);

    setLoading(false);

    if (doc) {
      return setMessage('Email está sendo enviado ao cliente.');
    }

    return setMessage('Algo deu errado, favor refaça a operação');
  };

  return (
    <Modal open={open} onClose={() => close()}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 1,
            minHeight: '50%',
            width: '50%',
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            padding: '10px',
            boxShadow: 24,
            margin: '100px auto'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 1,
            minHeight: '50%',
            width: '50%',
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            padding: '10px',
            boxShadow: 24,
            margin: '100px auto'
          }}
        >
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ textAlign: 'center' }}>
            <Typography variant="h4">Enviar Fatura</Typography>
            <FormControl>
              <TextField
                id="emails"
                label="Email"
                variant="outlined"
                onChange={handleChange}
                value={email}
              />
            </FormControl>
          </Box>
          <Stack>
            {list.map((e, i) => (
              <Chip
                label={e}
                onDelete={() => setList(list.filter((_, index) => index !== i))}
                key={Math.random()}
                sx={{ margin: '2px' }}
              />
            ))}
          </Stack>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleSend}
            disabled={list.length === 0}
          >
            Enviar Fatura
          </Button>
          <Dialog
            open={message !== null}
            onClose={() => {
              setMessage(null);
              close();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Fatura</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Modal>
  );
}

Send.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func
};
