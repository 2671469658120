import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import { auth } from '../services';

const AuthContext = createContext({
  token: null,
  profile: null,
  authorized: false,
  signIn: async () => {},
  signOut: () => {},
  refresh: () => {}
});

function parse(value) {
  try {
    if (value) {
      return JSON.parse(value);
    }

    return null;
  } catch {
    return value;
  }
}

function initialState(key) {
  try {
    return parse(window.localStorage.getItem(key));
  } catch {
    return null;
  }
}

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(initialState('@seteloc:token'));
  const [profile, setProfile] = useState(initialState('@seteloc:profile'));
  const [authorized, setAuthorized] = useState(initialState('@seteloc:authorized'));

  async function signIn({ email, password }) {
    const { profile, token } = await auth.signIn(email, password);

    if (profile && token) {
      setAuthorized(true);
      setToken(token);
      setProfile(profile);

      return true;
    }

    return false;
  }

  function signOut() {
    auth.signOut();
    setAuthorized(false);
    setToken(null);
    setProfile(null);

    return !!auth.profile;
  }

  function refresh({ document, company }) {
    setProfile((e) => {
      localStorage.setItem('@seteloc:profile', JSON.stringify({ ...e, document, company }));

      return { ...e, document, company };
    });
  }

  return (
    <AuthContext.Provider value={{ profile, token, authorized, signIn, signOut, refresh }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
