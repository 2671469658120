import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import React from 'react';

BuyOrderFilter.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func
};

export default function BuyOrderFilter({ filters, setFilters }) {
  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setFilters({ ...filters, buyOrder: e.target.value });
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label="Ordem de compra"
          name="buyOrder"
          variant="outlined"
          onChange={(e) => handleChange(e)}
          value={filters.buyOrder}
          sx={{ display: 'flex' }}
        />
      </FormControl>
    </Box>
  );
}
