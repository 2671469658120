import * as api from './api';

export const getCustomersTotvs = async () => {
  try {
    const { data } = await api.get('totvs-customers/totvs/');
    return data.filter((e) => !!e);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findOneCustomerTotvs = async (document) => {
  try {
    const data = await api.get(`totvs-customers/totvs/${document}`);
    return data;
  } catch {
    return null;
  }
};
