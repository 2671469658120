import { Box } from '@mui/material';
import { ptBR as corePtBR } from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { ptBR as pickersPtBR } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React from 'react';

import palette from '../../theme/palette';
import shadows, { customShadows } from '../../theme/shadows';
import shape from '../../theme/shape';
import typography from '../../theme/typography';

const theme = createTheme(
  {
    palette,
    shape,
    typography,
    shadows,
    customShadows
  },
  ptBR,
  pickersPtBR,
  corePtBR
);

export default function DataGridMain(props) {
  return (
    <ThemeProvider theme={theme}>
      {props?.gridRows?.length > 0 ? (
        <DataGrid
          sx={{
            outline: '0',
            '& .MuiDataGrid-footerContainer': {
              display: 'flex',
              justifyContent: 'center'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            '& .MuiDataGrid-row:hover': {
              cursor: props.onCellClick ? 'pointer' : 'default'
            },
            border: 'none'
          }}
          getRowId={props.getRowId ?? undefined}
          rows={props.gridRows}
          rowCount={props.rowCount}
          columns={props.gridColumns}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Download',
            toolbarExportPrint: 'Imprimir',
            columnMenuLabel: 'Menu',
            columnMenuShowColumns: 'Mostrar colunas',
            columnMenuFilter: 'Filtro',
            columnMenuHideColumn: 'Ocultar',
            columnMenuUnsort: 'Aleatório',
            columnMenuSortAsc: 'ASC',
            columnMenuSortDesc: 'DESC',
            filterPanelColumns: 'Colunas',
            filterPanelOperators: 'Operador',
            filterPanelInputLabel: 'Valor',
            filterPanelInputPlaceholder: 'Valor do filtro',
            filterOperatorContains: 'contém',
            filterOperatorEquals: 'é igual a',
            filterOperatorStartsWith: 'começa com',
            filterOperatorEndsWith: 'termina com',
            filterOperatorIsEmpty: 'está vazio',
            filterOperatorIsNotEmpty: 'não está vazio',
            filterOperatorIsAnyOf: 'é qualquer um',
            filterOperatorIs: 'é',
            filterOperatorNot: 'não é',
            filterOperatorAfter: 'é depois',
            filterOperatorOnOrAfter: 'está ligado ou depois',
            filterOperatorBefore: 'é antes',
            filterOperatorOnOrBefore: 'está ligado ou antes',
            noRowsLabel: 'Nenhum dado encontrado',
            columnsPanelTextFieldLabel: 'Encontrar coluna',
            columnsPanelTextFieldPlaceholder: 'Título da coluna',
            columnsPanelDragIconLabel: 'Reordenar coluna',
            columnsPanelShowAllButton: 'Mostar todos',
            columnsPanelHideAllButton: 'Esconder todos',
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`
            }
          }}
          components={props.gridComponents}
          pageSize={props.pageSize}
          pagination
          paginationMode={props?.paginationMode || 'server'}
          componentsProps={{
            pagination: {
              labelRowsPerPage: 'Itens por página'
            }
          }}
          onPageChange={(newPage) => props.setPage(newPage)}
          onPageSizeChange={(newPageSize) => props.setPageSize(newPageSize)}
          getRowClassName={props.rowClassName}
          rowsPerPageOptions={[10, 20, 30]}
          checkboxSelection={props.checkBox}
          onSelectionModelChange={(id) => {
            props.setRowSelectionModel(id);
          }}
          rowSelectionModel={props.rowSelectionModel}
          disableSelectionOnClick
          onCellClick={(params) => (props.onCellClick ? props.onCellClick(params.row) : null)}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          sx={{ background: '#fff' }}
        >
          <img
            src="https://cdn.seteloc.com.br/template/images/no-results-found.png"
            alt="Não encontramos o seu resultado."
          />
        </Box>
      )}
    </ThemeProvider>
  );
}
DataGridMain.propTypes = {
  gridRows: PropTypes.array,
  gridColumns: PropTypes.array,
  gridComponents: PropTypes.object,
  rowCount: PropTypes.number,
  pageSize: PropTypes.number,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  rowClassName: PropTypes.func,
  paginationMode: PropTypes.string,
  setRowSelectionModel: PropTypes.any,
  rowSelectionModel: PropTypes.any,
  checkBox: PropTypes.bool,
  onCellClick: PropTypes.func,
  getRowId: PropTypes.func
};
