export const translateBillType = [];

translateBillType.rent = 'Locação';
translateBillType.excess = 'KM Excedente';
translateBillType.refund = 'Reembolso';
translateBillType.ticket = 'Multa';
translateBillType.debitNote = 'Nota de débito';
translateBillType.other = 'Outros';

export const translateBillFileType = [];

translateBillFileType.billet = 'Boleto';
translateBillFileType.deposit = 'Depósito';
translateBillFileType.infringement = 'Infração';
translateBillFileType.notification = 'Notificação';
translateBillFileType.ticket = 'Multa';
translateBillFileType.other = 'Outros';
translateBillFileType.demonstrative = 'Demonstrativo';
translateBillFileType.rentInvoice = 'Nota de locação';
translateBillFileType.invoice = 'Nota fiscal';
translateBillFileType.excelDemonstrative = 'Demonstrativo em Excel';
translateBillFileType.proRatas = 'Pro Ratas';
translateBillFileType.debitNote = 'Nota de débito';
translateBillFileType.calculationMemory = 'Memoria de cálculo';
translateBillFileType.nic = 'NIC';
