import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const CnpjCpfSwitch = ({ cnpjCpfSwitch, setCnpjCpfSwitch }) => (
  <FormGroup sx={{ width: '20%' }}>
    <FormControlLabel
      onChange={() => setCnpjCpfSwitch(!cnpjCpfSwitch)}
      control={<Switch defaultChecked />}
      label="CNPJ/CPF"
    />
  </FormGroup>
);

CnpjCpfSwitch.propTypes = {
  cnpjCpfSwitch: PropTypes.object,
  setCnpjCpfSwitch: PropTypes.func.isRequired
};

export default CnpjCpfSwitch;
