function removeSpecialCharactersFromCnpj(cnpj) {
  return cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
}

function removeSpecialCharactersFromCpf(cpf) {
  return cpf.replace('.', '').replace('.', '').replace('.', '').replace('-', '');
}

function removeSpecialCharactersFromPhone(phone) {
  return phone.replace('(', '').replace(')', '').replace(' ', '').replace(' ', '').replace(' ', '');
}

function removeSpecialCharactersFromPhone2(phone) {
  return phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
}

export {
  removeSpecialCharactersFromCnpj,
  removeSpecialCharactersFromCpf,
  removeSpecialCharactersFromPhone,
  removeSpecialCharactersFromPhone2
};
