import { Cancel, DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputAdornment, InputLabel, MenuItem } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { random } from 'src/services/hashFunc';
import {
  useBillingsToSaveStore,
  verifyEditFields,
  verifyFields,
  verifySendFields
} from 'src/services/store';
import HandleEditFile from './handleEditFile';
import HandleSaveFile from './handleSaveFile';
import {
  BillingFilesContainer,
  BillingInput,
  EmailItem,
  EmailListContainer,
  NewBillingBox,
  NewBillingButton,
  NewBillingContainer,
  NewBillingFilesTitle,
  NewBillingFormControl,
  NewBillingInput,
  NewBillingSelect,
  NewBillingTitle,
  NoFilesText,
  TitleContainer
} from './style';

export default function BillingForm(props) {
  const { billIndex, removeBilling, billingTitle, vehiclesList, billingData, formType } = props;
  const [hasBillingData, setHasBillingData] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [billType, setBillType] = useState(null);
  const [countInputs, setCountInputs] = useState([]);
  const [billCode, setBillCode] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [billValue, setBillValue] = useState(null);
  const [trackingId, setTrackingId] = useState(null);
  const [buyOrder, setBuyOrder] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('Pressione enter para adicionar');
  const fileInputRef = useRef(null);
  const billingsToSave = useBillingsToSaveStore((state) => state.billingsToSave);

  useEffect(() => {
    if (billingData) {
      setHasBillingData(true);
      setBillType(billingData.origin);
      setBillCode(billingData.billCode);
      setDueDate(billingData.dueDate);
      setBillValue(billingData.billValue);
      setTrackingId(billingData.trackingId);
      setClientName(billingData.clientName);
      setEmailList(billingData.emailList);
      setBuyOrder(billingData.buyOrder);
      const updateInputs = [];

      billingData.bill.forEach((bill) => {
        updateInputs.push({ index: bill.key });
      });
      setCountInputs([...countInputs, ...updateInputs]);
    }
  }, []);

  const options = [
    {
      key: 'Multas',
      value: 'ticket'
    },
    {
      key: 'Locação',
      value: 'rent'
    },
    {
      key: 'Nota de Débito',
      value: 'debitNote'
    },
    {
      key: 'KM Excedente',
      value: 'excess'
    },
    {
      key: 'Outras Receitas',
      value: 'other'
    }
  ];

  const handleInputButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const update = billingsToSave;

      const updateInputs = [];

      if (formType === 'send' || formType === 'edit') {
        update.forEach((billing) => {
          if (billing.id === billIndex) {
            filesArray.forEach((file) => {
              const hash = random();
              updateInputs.push({ index: hash });
              billing.newFiles[hash] = {
                inputIndex: hash,
                file,
                fileType: null,
                ticketInfo: null
              };
            });
          }
        });
        useBillingsToSaveStore.setState({ billingsToSave: update });
        if (formType === 'send') {
          const isDisabled = verifySendFields(billingsToSave);
          useBillingsToSaveStore.setState({ disableSave: isDisabled });
        } else {
          const isDisabled = verifyEditFields(billingsToSave);
          useBillingsToSaveStore.setState({ disableSave: isDisabled });
        }
      } else {
        filesArray.forEach((file) => {
          const hash = random();
          updateInputs.push({ index: hash });
          update[billIndex].files[hash] = {
            inputIndex: hash,
            file: null,
            fileType: null,
            ticketInfo: null
          };
          update[billIndex].files[hash].file = file;
          useBillingsToSaveStore.setState({ billingsToSave: update });

          const isDisabled = verifyFields(billingsToSave);
          useBillingsToSaveStore.setState({ disableSave: isDisabled });
        });
      }
      setCountInputs([...countInputs, ...updateInputs]);
    } else {
      fileInputRef.current.value = null;
    }
  };

  const addBillToList = (value, field) => {
    const billings = billingsToSave;

    if (formType === 'send' || formType === 'edit') {
      billings.forEach((billing) => {
        if (billing.id === billIndex) {
          billing[field] = value;
        }
      });

      if (formType === 'send') {
        const isDisabled = verifySendFields(billingsToSave);
        useBillingsToSaveStore.setState({ disableSave: isDisabled });
      } else {
        const isDisabled = verifyEditFields(billingsToSave);
        useBillingsToSaveStore.setState({ disableSave: isDisabled });
      }
    } else {
      if (field === 'billValue') {
        value = +value;
      }
      billings[billIndex][field] = value;
      useBillingsToSaveStore.setState({ billingsToSave: billings });
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }

    switch (field) {
      case 'billCode':
        setBillCode(value);
        break;
      case 'dueDate':
        setDueDate(value);
        break;
      case 'trackingId':
        setTrackingId(value);
        break;
      case 'billingType':
        setBillType(value);
        break;
      case 'billValue':
        setBillValue(value);
        break;
      case 'buyOrder':
        setBuyOrder(value);
        break;
      default:
        break;
    }

    if (billCode == '') {
      useBillingsToSaveStore.setState({ disableSave: true });
    }

    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else if (formType === 'edit') {
      const isDisabled = verifyEditFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
  };

  const handleRemoveBilling = () => {
    removeBilling();

    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else if (formType === 'edit') {
      const isDisabled = verifyEditFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
  };

  const handleRemoveFile = (index) => {
    setCountInputs(countInputs.filter((input) => input.index !== index));
    const update = billingsToSave;
    delete update[billIndex].files[index];
    useBillingsToSaveStore.setState({ billingsToSave: update });
    fileInputRef.current.value = null;
    const isDisabled = verifyFields(billingsToSave);
    useBillingsToSaveStore.setState({ disableSave: isDisabled });
  };

  const removeFileToSend = (index) => {
    billingsToSave.forEach((billing) => {
      if (billing.id === billIndex) {
        billing.bill = billing.bill.filter((bill) => bill.key !== index);
        const update = countInputs.filter((input) => input.index !== index);
        setCountInputs(update);
      }
    });

    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else if (formType === 'edit') {
      const isDisabled = verifyEditFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
  };

  const handleEmailList = (event) => {
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      event.target.value
    );

    if (event.target.value.includes(';')) {
      const emailsArr = event.target.value.split(';');
      setEmailList([...emailList, ...emailsArr]);
      const update = billingsToSave;
      update.forEach((billing) => {
        if (billing.id === billIndex) {
          emailsArr.forEach((email) => {
            billing.emailList.push(email);
          });
        }
      });
      useBillingsToSaveStore.setState({ billingsToSave: update });
    } else if (!validEmail) {
      setEmailError(true);
      setEmailHelperText('Email inválido, tente novamente');
      event.preventDefault();
      return;
    } else {
      setEmailError(false);
      setEmailHelperText('Pressione enter para adicionar');

      setEmailList([...emailList, event.target.value]);

      const update = billingsToSave;
      update.forEach((billing) => {
        if (billing.id === billIndex) {
          billing.emailList.push(event.target.value);
        }
      });
      useBillingsToSaveStore.setState({ billingsToSave: update });
    }

    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else if (formType === 'edit') {
      const isDisabled = verifyEditFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
    event.target.value = '';
  };

  const handleRemoveEmail = (email) => {
    setEmailList(emailList.filter((item) => item !== email));
    const update = billingsToSave;
    update.forEach((billing) => {
      if (billing.id === billIndex) {
        billing.emailList = billing.emailList.filter((item) => item !== email);
      }
    });
    useBillingsToSaveStore.setState({ billingsToSave: update });

    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else if (formType === 'edit') {
      const isDisabled = verifyEditFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
  };

  const handleRemoveNewFile = (index) => {
    const update = billingsToSave;
    update.forEach((billing) => {
      if (billing.id === billIndex) {
        delete billing.newFiles[index];
        const update = countInputs.filter((input) => input.index !== index);
        setCountInputs(update);
      }
    });
    useBillingsToSaveStore.setState({ billingsToSave: update });
    if (formType === 'send') {
      const isDisabled = verifySendFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else if (formType === 'edit') {
      const isDisabled = verifyEditFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    } else {
      const isDisabled = verifyFields(billingsToSave);
      useBillingsToSaveStore.setState({ disableSave: isDisabled });
    }
  };

  return (
    <>
      <NewBillingContainer>
        <TitleContainer>
          <NewBillingTitle>Fatura {billingTitle + 1}</NewBillingTitle>
          {formType !== 'edit' && (
            <IconButton onClick={() => handleRemoveBilling()}>
              <DeleteForever />
            </IconButton>
          )}
        </TitleContainer>
        <>
          <NewBillingBox>
            {(formType === 'send' || formType === 'edit') && (
              <NewBillingInput
                type="text"
                placeholder="Cliente"
                label="Cliente"
                value={clientName}
                disabled
              />
            )}
            <NewBillingInput
              type="number"
              onChange={(e) => addBillToList(e.target.value, 'trackingId')}
              placeholder="ID de rastreamento"
              label="ID de rastreamento"
              value={trackingId}
            />
            <NewBillingInput
              type="number"
              onChange={(e) => addBillToList(e.target.value, 'billCode')}
              placeholder="Número da fatura"
              label="Número da fatura"
              value={billCode}
            />
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={dueDate}
                label="Data de vencimento"
                onChange={(value) => {
                  addBillToList(value, 'dueDate');
                }}
                renderInput={(params) => (
                  <NewBillingInput
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Data de vencimento'
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <NewBillingFormControl>
              {hasBillingData ? (
                <InputLabel shrink id="bill-options">
                  Tipo de fatura
                </InputLabel>
              ) : (
                <InputLabel id="bill-options">Tipo de fatura</InputLabel>
              )}
              <NewBillingSelect
                labelId="bill-options"
                label="Tipo de fatura"
                onChange={(e) => addBillToList(e.target.value, 'billingType')}
                value={billType}
                disabled={hasBillingData}
              >
                {options.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.key}
                  </MenuItem>
                ))}
              </NewBillingSelect>
            </NewBillingFormControl>
            <NewBillingInput
              type="number"
              onChange={(e) => addBillToList(e.target.value, 'billValue')}
              placeholder="Valor da fatura"
              label="Valor da fatura"
              value={billValue}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }}
            />
            <NewBillingInput
              type="number"
              onChange={(e) => addBillToList(e.target.value, 'buyOrder')}
              placeholder="Ordem de compra"
              label="Ordem de compra"
              value={buyOrder}
            />
          </NewBillingBox>
        </>
        <BillingFilesContainer>
          <NewBillingFilesTitle>Arquivos da fatura</NewBillingFilesTitle>
          {(function () {
            switch (hasBillingData) {
              case true:
                const billing = billingsToSave.find((bill) => bill.id === billIndex);
                return (
                  <>
                    {billing?.bill?.map((file, index) => (
                      <HandleEditFile
                        inputIndex={file.key}
                        key={file.key}
                        billType={billType}
                        billIndex={billIndex}
                        removeInput={removeFileToSend}
                        billingFilesData={file}
                        clientDocument={billing.client}
                        formType={formType}
                      />
                    ))}
                    {countInputs.length > 0 && billing?.newFiles ? (
                      Object.values(billing.newFiles).map((file, index) => (
                        <HandleEditFile
                          inputIndex={file.inputIndex}
                          key={file.inputIndex}
                          billType={billType}
                          billIndex={billIndex}
                          removeInput={handleRemoveNewFile}
                          vehiclesList={vehiclesList}
                          isNewFile
                          billingFilesData={file}
                          clientDocument={billing.client}
                          formType={formType}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                );
              default:
                return billingsToSave[billIndex] ? (
                  Object.values(billingsToSave[billIndex].files).map((file) => (
                    <>
                      <HandleSaveFile
                        inputIndex={file.inputIndex}
                        key={file.inputIndex}
                        billType={billType}
                        billIndex={billIndex}
                        removeInput={handleRemoveFile}
                        vehiclesList={vehiclesList}
                      />
                    </>
                  ))
                ) : (
                  <NoFilesText>Não há arquivos relacionados a fatura.</NoFilesText>
                );
            }
          })()}
        </BillingFilesContainer>
        <BillingInput
          type="file"
          multiple
          ref={fileInputRef}
          onChange={(e) => handleFileChange(e)}
        />
        <EmailListContainer>
          {formType === 'send' && (
            <NewBillingInput
              placeholder="E-mail"
              type="email"
              helperText={emailHelperText}
              error={emailError}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEmailList(e);
                }
              }}
            />
          )}
          {emailList.length > 0 &&
            emailList.map((email, index) => (
              <EmailItem key={index}>
                {email}
                <IconButton onClick={() => handleRemoveEmail(email)}>
                  <Cancel />
                </IconButton>
              </EmailItem>
            ))}
        </EmailListContainer>
        <NewBillingButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleInputButtonClick()}
        >
          Adicionar arquivo
        </NewBillingButton>
      </NewBillingContainer>
    </>
  );
}

BillingForm.propTypes = {
  billIndex: PropTypes.string.isRequired,
  removeBilling: PropTypes.func.isRequired,
  billingTitle: PropTypes.number.isRequired,
  vehiclesList: PropTypes.array,
  billingData: PropTypes.object,
  formType: PropTypes.string,
  typeOfBilling: PropTypes.string
};
