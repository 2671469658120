import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';

const ClientFilterMode = ({ setClientFilterMode }) => {
  const handleChange = (event) => {
    setClientFilterMode(event.target.value);
  };

  return (
    <FormControl style={{ display: 'block', boxSizing: 'content-box' }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="name"
        name="radio-buttons-group"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel
          value="name"
          control={<Radio onChange={handleChange} />}
          label="Cliente"
        />
        <FormControlLabel
          value="document"
          control={<Radio onChange={handleChange} />}
          label="CNPJ/CPF"
        />
      </RadioGroup>
    </FormControl>
  );
};

ClientFilterMode.propTypes = {
  setClientFilterMode: PropTypes.func
};

export default ClientFilterMode;
