export const ptBr = {
  RENT: 'Locação',
  EXCESS: 'KM Excedente',
  REFUND: 'Reembolso',
  TICKET: 'Multa',
  VALIDATED: 'Validado',
  PENDING: 'Pendente',
  WAITING: 'Aguardando',
  VALIDATION: 'validação',
  SEND: 'envio',
  CONTESTED: 'Contestado',
  UPCOMING: 'Próximo',
  OVERDUE: 'Atrasado',
  UPTODATE: 'Em dia',
  CONTRACT: 'Contrato',
  COMMERCIALOFFER: 'Oferta',
  CREATE: 'Criado',
  SENT: 'Enviado',
  UPDATED: 'Atualizado',
  CRLV: 'CRLV',
  NO_TELEMETRY: 'Sem telemetria',
  IDENTIFY: 'Identificação pendente',
  INANALYSIS: 'Validação pendente',
  IDENTIFIED: 'Identificado',
  INCONSISTENT: 'Inconsistência(s) encontrada(s)',
  LIGHT: 'Leve',
  MEDIUM: 'Média',
  HEAVY: 'Grave',
  SEVERE: 'Gravíssima',
  DEBITNOTE: 'Nota de débito',
};
