import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { random } from 'src/services/hashFunc';
import { useBillingsToSaveStore, verifyFields } from 'src/services/store';
import { Page } from '../../../../components';
import { billing, customers } from '../../../../services';
import BillingForm from '../components/BillingForm';

export default function NewBilling() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [clientsList, setClientsList] = useState([]);
  const [client, setClient] = useState(null);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [helperText, setHelperText] = useState(' ');
  const [errorValidate, setErrorValidate] = useState(false);
  const [disableAutocomplete, setDisableAutocomplete] = useState(false);
  const [disableNewBillingButton, setDisableNewBillingButton] = useState(false);
  const navigate = useNavigate();
  const [billingList, setBillingList] = useState([]);

  const billingsToSave = useBillingsToSaveStore((state) => state.billingsToSave);
  const disableSave = useBillingsToSaveStore((state) => state.disableSave);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await customers.getCustomersTotvs();
        if (data) {
          setClientsList(
            data
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((e) => ({ ...e, label: `${e.name} - ${e.document}` }))
          );
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    useBillingsToSaveStore.setState((state) => ({ billingsToSave: [] }));
    load();
  }, []);
  useEffect(() => {
    if (!client) {
      setDisableNewBillingButton(true);
    } else {
      setDisableNewBillingButton(false);
    }
  }, [client]);

  const handleButtonClick = async () => {
    const hash = random();
    setBillingList([...billingList, { index: hash }]);
    const update = billingsToSave;
    update[hash] = {
      index: hash,
      billingType: null,
      billCode: null,
      dueDate: null,
      files: [],
      trackingId: null,
      billValue: null,
      buyOrder: null
    };
    useBillingsToSaveStore.setState((state) => ({ billingsToSave: update }));
    useBillingsToSaveStore.setState((state) => ({ disableSave: true }));

    const vehiclesList = [];
    setVehiclesList(vehiclesList.map((vehicle) => vehicle.plate));

    if (Object.values(billingsToSave).length > 0) {
      setDisableAutocomplete(true);
    }
  };

  const removeBilling = (index) => {
    const filteredList = billingList.filter((billing) => billing.index !== index);
    setBillingList(filteredList);
    const updateBillingsToSave = billingsToSave;
    delete updateBillingsToSave[index];
    useBillingsToSaveStore.setState((state) => ({ billingsToSave: updateBillingsToSave }));
    const isDisabled = verifyFields(billingsToSave);
    useBillingsToSaveStore.setState({ disableSave: isDisabled });

    if (filteredList.length === 0) {
      setDisableAutocomplete(false);
    }
  };
  const handleSend = async () => {
    setLoading(true);
    try {
      const billsList = Object.values(billingsToSave);
      for (let i = 0; i < billsList.length; i++) {
        let filesToSave = [];
        const values = Object.values(billsList[i].files);
        for (let j = 0; j < values.length; j++) {
          let billFiles;

          if (values[j].ticketInfo) {
            billFiles = await billing.sendFiles(
              [values[j].file],
              values[j].fileType,
              billsList[i].billingType,
              values[j].ticketInfo
            );
          } else {
            billFiles = await billing.sendFiles(
              [values[j].file],
              values[j].fileType,
              billsList[i].billingType
            );
          }

          filesToSave = [...filesToSave, billFiles[0]];
        }
        await billing.createBilling(
          client.document,
          billsList[i].billCode,
          filesToSave,
          billsList[i].dueDate,
          billsList[i].billValue,
          billsList[i].trackingId,
          billsList[i].buyOrder
        );
      }
      useBillingsToSaveStore.setState((state) => ({ billingsToSave: [] }));
      setLoading(false);
      return setMessage('Fatura criada com sucesso');
    } catch {
      setLoading(false);
      return setMessage('Algo deu errado, refaça a operação');
    }
  };

  const handleCancel = () => {
    setClient(null);
    useBillingsToSaveStore.setState((state) => ({ billingsToSave: [] }));
    useBillingsToSaveStore.setState((state) => ({ disableSave: true }));
    navigate('/billing');
  };

  useEffect(() => {
    const isDisabled = verifyFields(billingsToSave);
    useBillingsToSaveStore.setState({ disableSave: isDisabled });
  }, []);

  return (
    <Page title="Nova fatura" loading={loading}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          p: '25px',
          mb: '5px'
        }}
      >
        <Typography variant="h3" sx={{ mb: '10px' }}>
          Nova Fatura
        </Typography>
        <div style={{ width: '100%', height: '1px', backgroundColor: '#E77E22' }} />
      </Box>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          p: '25px',
          mb: '5px'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Autocomplete
            getOptionLabel={(option) => option.label}
            sx={{
              width: '40%',
              height: '40px'
            }}
            options={clientsList}
            size="small"
            disabled={disableAutocomplete}
            disableClearable
            onInputChange={(e, value) => {
              if (e.type === 'click' || e.type === 'keydown') {
                const auxArray = value.split(' - ');
                setClient(clientsList.find((c) => c.document === auxArray[auxArray.length - 1]));
                if (value.length > 0) {
                  setHelperText(' ');
                  setErrorValidate(false);
                } else {
                  setHelperText('Este campo é obrigatório');
                  setErrorValidate(true);
                  setDisableAutocomplete(false);
                }
              }
            }}
            isOptionEqualToValue={(a, b) => a.label.toLowerCase() === b.label.toLowerCase()}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorValidate}
                placeholder="Cliente ou CNPJ"
                helperText={helperText}
              />
            )}
          />
          <Button
            sx={{
              borderRadius: '8px',
              bgcolor: '#D67131',
              color: '#ffffff',
              p: '10px',
              gap: '10px',
              fontSize: '14px',
              height: '40px',

              '&:hover': {
                backgroundColor: '#D67131',
                color: '#ffffff'
              }
            }}
            onClick={handleButtonClick}
            disabled={disableNewBillingButton}
          >
            Nova fatura
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginBottom: '50px' }}>
        {billingList.length > 0 &&
          billingList.map((billing, index) => (
            <Box sx={{ borderRadius: '8px', margin: '8px' }} key={billing.index}>
              <BillingForm
                billIndex={billing.index}
                key={billing.index}
                removeBilling={() => removeBilling(billing.index)}
                billingTitle={index}
                vehiclesList={vehiclesList}
              />
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          display: 'flex',
          width: '90%',
          justifyContent: 'space-between',
          margin: '20px 100px'
        }}
      >
        <Button variant="contained" onClick={() => handleCancel()} color="error">
          Cancelar
        </Button>
        <Button variant="contained" disabled={disableSave || !client} onClick={() => handleSend()}>
          Salvar
        </Button>
      </Box>
      <Dialog
        open={message !== null}
        onClose={() => window.location.reload()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fatura</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
