import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { toast } from 'react-toastify';
import { Lock } from '@mui/icons-material';

import { Stack, TextField, IconButton, InputAdornment, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from '../../../../services';
import { PasswordCheck } from './PasswordCheck';
import {
  MIN_CHARACTERS_REGEX,
  SPECIAL_CHARACTER_REGEX,
  LOWER_CASE_REGEX,
  UPPER_CASE_REGEX,
  NUMBER_REGEX,
  MAX_CHARACTERS_REGEX,
  SPACE_NOT_ALLOWED,
  validatePasswordSecurity
} from '../../../../utils/validate-password-security';

export default function RegisterForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const PasswordValidation = Yup.object().shape({
    password: Yup.string().required('Digite uma senha válida'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password')], 'Senhas não correspondem')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    validationSchema: PasswordValidation,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      if (!validatePasswordSecurity(values.password)) {
        toast.error(
          'A senha não atende aos critérios mínimos de segurança, ajuste e tente novamente'
        );
        setLoading(false);
        return;
      }

      const response = await api.patch(`user/change/password`, values);

      if (!response.statusCode) {
        toast.success('Senha alterada com sucesso!');
        setLoading(false);
        resetForm();
        return;
      }

      toast.error(response?.message || 'Ocorreu algum erro no processo!');
      setLoading(false);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Nova Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={
              (touched.password && errors.password) ||
              (touched.passwordConfirm && errors.passwordConfirm)
            }
            helperText={
              (touched.password && errors.password) ||
              (touched.passwordConfirm && errors.passwordConfirm)
            }
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Repita Nova Senha"
            {...getFieldProps('passwordConfirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={
              (touched.password && errors.password) ||
              (touched.passwordConfirm && errors.passwordConfirm)
            }
            helperText={
              (touched.password && errors.password) ||
              (touched.passwordConfirm && errors.passwordConfirm)
            }
          />

          <Grid container flexDirection="column">
            <Grid item display="flex" marginBottom="1rem">
              <Lock fontSize="large" />
              <Typography marginLeft=".5rem" fontWeight="500" lineHeight="2.5">
                Sua senha deve cumprir os critérios mínimos abaixo:
              </Typography>
            </Grid>
            <PasswordCheck
              label="Mínimo de 8 caractéres"
              isValid={
                formik.values.password ? MIN_CHARACTERS_REGEX.test(formik.values.password) : null
              }
            />
            <PasswordCheck
              label="Pelo menos 1 caractere especial (!@#$%^&*)"
              isValid={
                formik.values.password ? SPECIAL_CHARACTER_REGEX.test(formik.values.password) : null
              }
            />
            <PasswordCheck
              label="Pelo menos 1 letra maiúscula (A-Z)"
              isValid={
                formik.values.password ? UPPER_CASE_REGEX.test(formik.values.password) : null
              }
            />
            <PasswordCheck
              label="Pelo menos 1 letra minúscula (a-z)"
              isValid={
                formik.values.password ? LOWER_CASE_REGEX.test(formik.values.password) : null
              }
            />
            <PasswordCheck
              label="Pelo menos 1 numeral (1-9)"
              isValid={formik.values.password ? NUMBER_REGEX.test(formik.values.password) : null}
            />
            <PasswordCheck
              label="Máximo 16 caracteres"
              isValid={
                formik.values.password ? MAX_CHARACTERS_REGEX.test(formik.values.password) : null
              }
            />
            <PasswordCheck
              label="Não deve conter espaços"
              isValid={
                formik.values.password ? SPACE_NOT_ALLOWED.test(formik.values.password) : null
              }
            />
          </Grid>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Trocar
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
