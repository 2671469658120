import * as yup from 'yup';
import { phoneRegex } from '../../../../utils/regex';

export const permissions = [
  { permissionName: 'user', permissionNameInPortuguese: 'Usuário' },
  { permissionName: 'admin', permissionNameInPortuguese: 'Administrador' },
  { permissionName: 'manager', permissionNameInPortuguese: 'Cliente' },
  { permissionName: 'robot', permissionNameInPortuguese: 'Robô' },
  { permissionName: 'seteloc', permissionNameInPortuguese: 'Seteloc' },
  { permissionName: 'superadmin', permissionNameInPortuguese: 'Super Administrador' },
  { permissionName: 'finevalidator', permissionNameInPortuguese: 'Validador de Multas' }
];

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const schema = yup.object().shape({
  firstName: yup.string().required('Preenchimento de nome necessário'),
  document: yup.array().required('Preenchimento das empresas obrigatório'),
  active: yup.boolean().required('O usuário precisa estar ativo ou não'),
  pending: yup.boolean().required('O usuário precisa estar pendente ou não'),
  phone: yup
    .string()
    .required('Preenchimento de telefone necessário')
    .matches(phoneRegex, 'Formato do telefone incorreto'),
  email: yup.string().email().required('Preenchimento de e-mail necessário ou formato incorreto'),
  roles: yup.array().required('Preenchimento do campo de permissões necessário')
});
