import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Polyline = ({ ...options }) => {
  const [polyline, setPolyline] = useState();

  useEffect(() => {
    if (!polyline) {
      const polyline = new window.google.maps.Polyline({
        path: options.coordinates,
        geodesic: true,
        strokeColor: '#E77E22',
        strokeOpacity: 1.0,
        strokeWeight: 2
      });

      setPolyline(polyline);
    }

    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polyline]);

  useEffect(() => {
    if (polyline) {
      polyline.setOptions(options);
    }
  }, [polyline, options]);

  return null;
};

Polyline.propTypes = {
  options: PropTypes.object
};
