import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { AccountCircle, LockReset } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, MenuItem, Typography } from '@mui/material';

import jwtDecode from 'jwt-decode';
import { useAuth } from '../../../contexts';
import MenuPopover from '../../../components/MenuPopover';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { signOut, profile, token } = useAuth();

  const jwt = token ? jwtDecode(token) : null;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    signOut();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          color: '#FFFFFF',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[300], 0.72)
            }
          })
        }}
      >
        <AccountCircle color="#fff" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 250 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile && profile?.name}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem
          to="/settings/password"
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={LockReset}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />
          Redefinir senha
        </MenuItem>
        {Array.isArray(jwt?.document) && jwt?.document.length > 1 && (
          <MenuItem
            to="/company"
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={ChangeCircleIcon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            Trocar Empresa
          </MenuItem>
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Sair
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
