import { Send as SendIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { billing } from '../../../../services';

export default function SendMany({ objMail, open, close }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [list, setList] = useState([]);
  const [textFields, setTextFields] = useState([]);

  useEffect(() => {
    // Função para gerar os dados iniciais dos TextField
    const generateInitialTextFields = () => {
      const initialTextFields = [];
      for (let i = 0; i < objMail?.length; i++) {
        initialTextFields.push({
          id: `${objMail[i].id}`,
          label: `Email`,
          value: ''
        });
      }
      return initialTextFields;
    };

    setTextFields(generateInitialTextFields());
  }, [objMail]);

  const handleChange = (event, rowID) => {
    const { value } = event.target;
    setTextFields((prevTextFields) =>
      prevTextFields.map((textField) =>
        textField.id === rowID ? { ...textField, value } : textField
      )
    );
  };

  const handleSubmit = (event, rowsSelected) => {
    event.preventDefault();

    for (let count = 0; count < objMail.length; count++) {
      if (objMail[count].id === rowsSelected) {
        const objAux = [...objMail[count].emails, textFields[count].value.split(/;\s*/)];
        objMail[count].emails = objAux.flat();
      }
    }
    setTextFields((prevTextFields) =>
      prevTextFields.map((textField) =>
        textField.id === rowsSelected ? { ...textField, value: '' } : textField
      )
    );
  };

  const verifyMails = () => {
    for (let count = 0; count < objMail?.length; count++) {
      if (objMail[count]?.emails?.length === 0) {
        return true;
      }
    }
    return false;
  };

  const handleSend = async () => {
    for (let count = 0; count < objMail.length; count++) {
      setLoading(true);
      // eslint-disable-next-line no-await-in-loop
      const doc = await billing.consolidate(objMail[count].id, objMail[count].emails);
      setLoading(false);
      if (!doc) {
        return setMessage(
          `Algo deu errado, favor refaça a operação, falha em enviar o ${count} email!`
        );
      }
    }

    return setMessage('Email enviado ao cliente com sucesso!.');
  };

  const handleDelete = async (i, rowsId) => {
    let updateEmail = [];
    for (let count = 0; count < objMail.length; count++) {
      if (objMail[count].id === rowsId) {
        for (let countMail = 0; countMail < objMail[count].emails.length; countMail++) {
          if (objMail[count].emails[countMail] !== objMail[count].emails[i]) {
            updateEmail = [...updateEmail, objMail[count].emails[countMail]];
          }
        }
        objMail[count].emails = updateEmail;
        setList(updateEmail);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const renderTextFields = (rowsId) => {
    const filteredTextFields = textFields.filter((textField) => textField.id === rowsId);
    if (filteredTextFields.length > 0) {
      const textFieldData = filteredTextFields[0];
      return (
        <TextField
          key={textFieldData.id}
          id={textFieldData.id}
          label={textFieldData.label}
          value={textFieldData.value}
          onChange={(event) => handleChange(event, textFieldData.id)}
        />
      );
    }
  };

  return (
    <Modal open={open} onClose={() => close()}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 1,
            maxHeight: '50%',
            maxWidth: '50%',
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            padding: '10px',
            boxShadow: 24,
            margin: '100px auto'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            maxHeight: '80%',
            width: '50%',
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: 24,
            margin: '100px auto',
            overflowY: 'scroll'
          }}
        >
          {objMail?.map((rows) => (
            <>
              <Box sx={{ marginBottom: '10px' }}>
                <Box
                  key={rows.id + 1000}
                  component="form"
                  noValidate
                  onSubmit={(event) => handleSubmit(event, rows.id)}
                  sx={{ textAlign: 'center' }}
                >
                  <Typography variant="h5">{`Insira abaixo o e-mail referente a fatura de ${rows.type} da ${rows.name}.`}</Typography>
                  <Box>{renderTextFields(rows.id)}</Box>
                </Box>
                <Stack>
                  {rows?.emails?.map((obj, i) => (
                    <Chip
                      key={i}
                      label={obj}
                      onDelete={() => handleDelete(i, rows.id)}
                      sx={{ margin: '2px' }}
                    />
                  ))}
                </Stack>
                <Dialog
                  open={message !== null}
                  onClose={() => {
                    setMessage(null);
                    close();
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Fatura</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                  </DialogContent>
                </Dialog>
              </Box>
            </>
          ))}
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleSend}
            disabled={verifyMails()}
          >
            Enviar Fatura
          </Button>
        </Box>
      )}
    </Modal>
  );
}

SendMany.propTypes = {
  objMail: PropTypes.array,
  open: PropTypes.bool,
  close: PropTypes.func
};
