import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,
  Select,
  FormControlLabel,
  Switch
} from '@mui/material';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import { removeSpecialCharactersFromPhone2 } from '../../../../utils/removeSpecialCharacters';
import { user } from '../../../../services';
import { Page } from '../../../../components';
import CnpjCpfChips from './components/CnpjCpfChips';
import { permissions, MenuProps, schema } from './helpers';

const UserEdit = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    document: [],
    active: false,
    pending: true,
    phone: '',
    email: '',
    roles: [],
    mainUser: false
  });
  useEffect(() => console.log('form', form), [form]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const doc = await user.findById(id);
      setForm({
        firstName: doc.firstName,
        lastName: doc.lastName,
        document: Array.isArray(doc.document) ? doc.document : [doc.document],
        active: doc.active,
        pending: doc.pending,
        phone: doc.phone,
        email: doc.email,
        roles: doc.role ? doc.role : [],
        mainUser: doc.mainUser
      });
      setLoading(false);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'roles') {
      setForm({
        ...form,
        [name]: typeof value === 'string' ? value.split(',') : value
      });
    } else {
      setForm({
        ...form,
        [name]: value
      });
    }
  };

  const handleUpdate = async () => {
    schema
      .validate(form)
      .then(async () => {
        const sendForm = {
          firstName: form.firstName,
          lastName: form.lastName,
          document: form.document,
          active: form.active,
          pending: form.pending,
          phone: removeSpecialCharactersFromPhone2(form.phone),
          email: form.email,
          role: form.roles,
          mainUser: form.mainUser
        };

        const data = await user.updateUser(id, sendForm);

        if (data.statusCode === 500) {
          toast.error('Houve um erro ao atualizar o usuário!');
        } else if (data.statusCode === 409) {
          toast.warning(data.message);
        } else {
          toast.success('SUCESSO! O usuário foi editado!');
          navigate('/user');
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  return (
    <Page title="Editar Usuário" loading={loading}>
      <Grid container padding={3} sx={{ flexGrow: 1 }}>
        <Grid marginLeft={3}>
          <Stack marginBottom={3} marginTop={4}>
            <Typography variant="h4" gutterBottom>
              Editar usuário
            </Typography>
          </Stack>
        </Grid>
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '75vh',
            width: '100%'
          }}
          elevation={3}
        >
          {form && (
            <Grid container height="100%" justifyContent="space-between">
              <Grid container height="10%" spacing={3} margin={5} justifyContent="space-between">
                <Grid item xs={6}>
                  <TextField
                    name="firstName"
                    label="Nome"
                    fullWidth
                    value={form.firstName}
                    type="text"
                    size="medium"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="lastName"
                    label="Sobrenome"
                    fullWidth
                    value={form.lastName}
                    type="name"
                    size="medium"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CnpjCpfChips form={form} setForm={setForm} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    label="Email"
                    fullWidth
                    value={form.email}
                    type="text"
                    size="medium"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    mask="(99) 99999-9999"
                    onChange={handleChange}
                    value={form.phone}
                    required
                  >
                    {() => (
                      <TextField
                        name="phone"
                        label="Telefone"
                        fullWidth
                        type="text"
                        size="medium"
                        required
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="active"
                    label="Status"
                    fullWidth
                    value={form.active}
                    select
                    size="medium"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem key="ativo" value>
                      Habilitado
                    </MenuItem>
                    <MenuItem key="desativado" value={false}>
                      Bloqueado
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="pending"
                    label="Pendente"
                    fullWidth
                    value={form.pending}
                    select
                    size="medium"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem key="active" value>
                      Pendente
                    </MenuItem>
                    <MenuItem key="disabled" value={false}>
                      Não pendente
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">Permissões</InputLabel>
                      <Select
                        name="roles"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={form.roles}
                        onChange={handleChange}
                        input={<OutlinedInput label="Permissões" />}
                        renderValue={(selected) => selected.join(', ')}
                        size="medium"
                        MenuProps={MenuProps}
                      >
                        {permissions.map((role) => (
                          <MenuItem key={role.permissionName} value={role.permissionName}>
                            <Checkbox
                              checked={
                                form.roles.length > 0
                                  ? form.roles.indexOf(role.permissionName) > -1
                                  : null
                              }
                            />
                            <ListItemText primary={role.permissionNameInPortuguese} la />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Usuário Principal"
                    control={
                      <Switch
                        checked={form.mainUser}
                        onChange={() => setForm({ ...form, mainUser: !form.mainUser })}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 10
                }}
              >
                <Grid item>
                  <Button
                    sx={{ marginLeft: 8 }}
                    variant="contained"
                    color="warning"
                    size="medium"
                    onClick={() => navigate('/user')}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ marginRight: 8 }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    type="submit"
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Dialog
            open={message !== null}
            onClose={() => setMessage(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{message && message.title}</DialogTitle>
            <DialogContent>{message && message.body} </DialogContent>
          </Dialog>
        </Paper>
      </Grid>
    </Page>
  );
};

export default UserEdit;
