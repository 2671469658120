import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { useAuth } from '../../contexts';
import { customers } from '../../services';

export default function Company() {
  const [list, setList] = useState([]);
  const [doc, setDoc] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const { refresh, token, profile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasRedirect, from } = location?.state || {};
  const home = '/user';
  const path = hasRedirect ? from.replace('/redirect', '') : home;

  useEffect(() => {
    setLoading(true);

    const { document } = jwtDecode(token);
    const search = Array.isArray(document) ? document : Array.of(document);

    if (search.length === 1) {
      refresh({ document: search[0], company: profile.company });
      return navigate(path, { replace: true });
    }

    const load = async () => {
      const data = await Promise.all(
        search.map((e) => {
          const doc = customers.findOneCustomerTotvs(e);
          return doc;
        })
      );

      const items = [];
      data.filter((e) => !e.statusCode && items.push({ document: e.document, name: e.name }));

      setList(items);
    };

    load();

    return setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = async () => {
    if (!doc) {
      return setError('Para continuar é necessário selecionar uma empresa');
    }
    const [data] = list.filter((e) => e.document === doc);

    refresh({ document: data.document, company: data.name });

    return navigate(path, { replace: true });
  };

  const handleSelect = (select) =>
    select.map((item) => (
      <MenuItem key={item.name} value={item.document}>
        {`${item.name} - ${item.document}`}
      </MenuItem>
    ));

  return (
    <Page title="Selecionar" loading={loading}>
      <Box
        sx={{ width: '100%' }}
        style={{ position: 'absolute', top: '20%', left: '20%' }}
        maxWidth="70%"
        alignItems="center"
      >
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h5"> Selecione qual Empresa/CNPJ deseja acessar</Typography>
          <TextField
            fullWidth
            select
            error={error}
            helperText={error}
            defaultValue=""
            label="Empresa/CNPJ"
            onChange={(e) => setDoc(e.target.value)}
          >
            {handleSelect(list)}
          </TextField>
          <Stack width="50%">
            <Button variant="contained" fullWidth onClick={handleNext}>
              Próximo
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Page>
  );
}
