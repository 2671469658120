import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Grid, Paper, Box } from '@mui/material';

const Background = styled('div')(() => ({
  height: '100%',
  position: 'relative',
  borderRadius: '5px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundImage: 'url(/static/illustrations/pick-up.png)'
}));

const Logo = styled('div')(() => ({
  top: '10px',
  left: '15px',
  width: '120px',
  height: '34px',
  backgroundPosition: 'center center',
  backgroundSize: '120px 34px',
  backgroundImage: 'url(/static/logo.png)',
  position: 'absolute'
}));

export default function PublicLayout() {
  return (
    <Box
      component="main"
      sx={{
        padding: 3,
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: '#f9f9f9'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid
            item
            md={6}
            lg={6}
            xl={6}
            sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
          >
            <Paper sx={{ maxWidth: 450, height: 700 }} elevation={3}>
              <Background>
                <Logo />
              </Background>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Container>
              <Outlet />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
