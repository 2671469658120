import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet, Link as RouterLink } from 'react-router-dom';

import { Box, Divider, IconButton, Stack, Toolbar } from '@mui/material';

import { Logo, Scrollbar } from '../components';
import { Navbar, Drawer, Notification, Account, Menu } from './components';

export default function PrivateLayout() {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (e) => {
    e.stopPropagation();

    setOpen(!open);
  };

  const handleClick = (e) => {
    e.stopPropagation();

    if (!open) {
      setOpen(true);
    }
  };

  return (
    <>
      <Navbar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px'
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '20px',
              ...(open && { display: 'none' })
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Notification />
            <Account />
          </Stack>
        </Toolbar>
      </Navbar>

      <Drawer variant="permanent" open={open} sx={{ backgroundColor: '#f9f9f9' }}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1]
          }}
        >
          <Box
            component={RouterLink}
            to="/"
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Logo />
          </Box>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Scrollbar
          sx={{
            height: '100%',
            '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
          }}
        >
          <Menu onClick={handleClick} handleClick={open} />
        </Scrollbar>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: '#f9f9f9'
        }}
      >
        <Toolbar />
        <Box sx={{ style: 'flex', p: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
