import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Header from './components/Header';

function RegConfirmation() {
  const navigate = useNavigate();

  return (
    <>
      <Container
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <Header />
        <Typography variant="h2" textAlign="left" marginBottom="2rem">
          Quase lá!
        </Typography>
        <Typography
          variant="h4"
          marginBottom="3rem"
          textAlign="center"
          paddingTop="1rem"
          fontWeight="normal"
        >
          Seu cadastro foi concluído e será validado em até 24 horas. <br />
          Não se preocupe, nós o avisaremos através do e-mail informado.
        </Typography>

        <Button
          type="button"
          variant="contained"
          onClick={() => navigate('/auth/sign-in', { replace: false })}
          size="large"
        >
          Ok!
        </Button>
      </Container>
    </>
  );
}

export default RegConfirmation;
