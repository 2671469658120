import auth from './auth';

const URL = process.env.REACT_APP_URL;

export const defaults = {
  headers: {
    Authorization: null,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

/**
 * @description Wrapper do API Fetch
 * @param {String} method Nome do metodo
 * @param {String} path Caminho da API
 * @param {Object} options Oções da requisição
 * @returns {Object} Retorna um object JSON da requisição
 */
const http = async (method, path, options = {}) => {
  try {
    const headers = options.headers || defaults.headers;

    options = {
      ...options,
      method: method.toUpperCase(),
      headers: {
        ...headers,
        Authorization: localStorage.getItem('@seteloc:token') || null
      }
    };

    let response = await fetch(`${URL}/${path}`, options);

    if (response.status === 401 && window.location.pathname !== '/auth/sign-in') {
      const refreshResponse = await auth.refreshToken();
      if (!refreshResponse) return window.location.replace('/auth/sign-in');
      response = await fetch(`${URL}/${path}`, {
        ...options,
        headers: {
          ...headers,
          Authorization: localStorage.getItem('@seteloc:token') || refreshResponse.token
        }
      });
    }

    if (
      !response.headers.get('Content-Type').includes('application/json') &&
      !response.headers.get('Content-Type').includes('text/html')
    ) {
      return { data: await response.blob(), mimetype: response.headers.get('Content-Type') };
    }

    return response.json();
  } catch ({ message }) {
    console.log(message);
    return {
      statusCode: 500,
      message
    };
  }
};

/**
 * @description Metodo GET
 * @param {String} path Caminho da requisição
 * @param {Object} options Opções da requisição
 * @returns {Object} Retorna um object JSON da requisição
 */
export const get = async (path, options) => http('GET', path, options);

/**
 * @description Metodo POST
 * @param {String} path Caminho da requisição
 * @param {Object} body Payload da requisição
 * @param {Object} options Opções da requisição
 * @returns {Object} Retorna um object JSON da requisição
 */
export const post = async (path, body, options = {}) => {
  options = {
    body: typeof body === 'string' ? body : JSON.stringify(body),
    ...options
  };

  return http('POST', path, options);
};

/**
 * @description Metodo POST para envio de arquivos
 * @param {String} path Caminho da requisição
 * @param {Object} body Payload da requisição
 * @param {Object} options Opções da requisição
 * @returns {Object} Retorna um object JSON da requisição
 */
export const postDocuments = async (path, body, options = {}) => {
  options = {
    ...options,
    body,
    headers: {}
  };

  return http('POST', path, options);
};

/**
 * @description Metodo PATCH
 * @param {String} path Caminho da requisição
 * @param {Object} body Payload da requisição
 * @param {Object} options Opções da requisição
 * @returns {Object} Retorna um object JSON da requisição
 */
export const patch = async (path, body, options = {}) => {
  options = {
    body: typeof body === 'string' ? body : JSON.stringify(body),
    ...options
  };

  return http('PATCH', path, options);
};

/**
 * @description Metodo DEL
 * @param {String} path Caminho da requisição
 * @param {Object} body Payload da requisição
 * @param {Object} options Opções da requisição
 * @returns {Object} Retorna um object JSON da requisição
 */
export const del = async (path, options) => http('DELETE', path, options);
