import { Delete as DeleteIcon, PostAdd, Send as SendIcon } from '@mui/icons-material';
import { Box, Button, Grid, MenuItem, Paper, TableContainer, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import _ from 'lodash';
import { Page } from '../../../components';
import DataGridMain from '../../../components/DataGridMain/DataGridMain';
import { billing, customers, internalLogs } from '../../../services';
import { useBillingsToSaveStore } from '../../../services/store';
import { TableOption } from '../../../shared/components/data-grip-table/option-item';
import { Translate } from '../../../utils/lang';
import { DeleteModal, SendMany, TableFilters } from './components/index';

import { valueforPTbr } from '../../../utils/valueforPT-br';

export default function Billing() {
  const FILTERS_INITIAL_STATE = {
    client: '',
    clientName: '',
    startDate: null,
    endDate: null,
    isVisualized: null,
    billCode: '',
    buyOrder: ''
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState(null);
  const [list, setList] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [clientFilterMode, setClientFilterMode] = useState('name');
  const [clients, setClients] = useState([]);
  const [notification, setNotification] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [sendMail, setSendMail] = useState(false);
  const [objEmail, setObjEmail] = useState([]);
  const mails = [];

  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);

  useEffect(() => {
    const loadClients = async () => {
      try {
        const data = await customers.getCustomersTotvs();
        setClients(
          data.map((client) => ({
            name: client.name,
            document: client.document
          }))
        );
      } catch (error) {
        toast.error('Falha ao recuperar clientes');
      }
    };
    useBillingsToSaveStore.setState((state) => ({ disableSave: true }));
    useBillingsToSaveStore.setState((state) => ({ billingsToSave: [] }));
    loadClients();
  }, []);

  useEffect(() => {
    const loadBillings = async () => {
      try {
        setLoading(true);
        await makeFilter();
        setLoading(false);
      } catch (error) {
        toast.error('Falha ao recuperar faturas');
      }
    };

    loadBillings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, page, pageSize]);

  useEffect(() => {
    const loadNotification = async () => {
      try {
        const notification = await internalLogs.findAll();
        setNotification(notification);
      } catch (error) {
        toast.error('Falha ao recuperar Notifiações');
      }
    };
    loadNotification();
  }, []);

  useEffect(() => {
    setFilters({ ...filters, client: '', clientName: '' });
  }, [clientFilterMode]);

  const handleDelete = (row) => {
    setShowModal(true);
    setRow(row);
  };

  const setStatus = (row) => {
    const findNotification = notification.filter((e) => e.protocol === row.protocol);
    if (findNotification[0]) {
      let findDownloaded = row.lastVisualized ? 'Visualizada' : 'Não Visualizada';
      findNotification.forEach((e) => {
        if (e.action === 'billing downloaded') {
          findDownloaded = 'Baixada';
        }
      });
      return findDownloaded;
    }

    if (row.sent) {
      return 'Enviada';
    }
    return 'Não Enviada';
  };

  const actions = [
    {
      render: (row) => (
        <MenuItem onClick={() => navigate(`/billing/sendBillings?id=${row.id}`)}>Enviar</MenuItem>
      )
    },
    {
      render: (row) => (
        <MenuItem onClick={() => navigate(`/billing/edit?id=${row.id}`)}>Editar</MenuItem>
      )
    },
    {
      render: (row) => (
        <MenuItem
          variant="text"
          color="error"
          endIcon={<DeleteIcon />}
          onClick={() => handleDelete(row)}
        >
          Deletar
        </MenuItem>
      )
    }
  ];

  const columns = [
    {
      field: 'id',
      headerName: 'protocol',
      minWidth: 150,
      flex: 1,
      editable: false,
      align: 'center',
      hide: true
    },
    {
      field: 'clientName',
      headerName: 'Cliente',
      minWidth: 302,
      flex: 1,
      editable: false,
      align: 'center'
    },
    {
      field: 'client',
      headerName: 'CNPJ/CPF',
      maxWidth: 164,
      flex: 1,
      editable: false,
      align: 'center'
    },
    {
      field: 'billCode',
      headerName: 'Numero da fatura',
      maxWidth: 135,
      flex: 1,
      editable: false,
      align: 'center'
    },
    {
      field: 'dueDate',
      headerName: 'Vencimento',
      maxWidth: 135,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <Typography>
          {new Date(row.createdAt).toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo'
          })}
        </Typography>
      ),
      align: 'center'
    },
    {
      field: 'protocol',
      headerName: 'Tipo de Fatura',
      maxWidth: 164,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        const { bill } = row;
        const origins = bill.map((e) => Translate(e.origin));
        const formattedOrigins = origins
          .filter((value, index, self) => self.indexOf(value) === index)
          .join(' | ');
        return <Typography>{formattedOrigins}</Typography>;
      },
      align: 'center'
    },
    {
      field: 'bill',
      headerName: 'Documentos',
      maxWidth: 145,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { bill } = row;

        return <Typography>{bill.length}</Typography>;
      },
      align: 'center'
    },
    {
      field: 'billValue',
      headerName: 'Valor da fatura',
      maxWidth: 145,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { billValue } = row;
        if (billValue > 0) {
          return <Typography>{valueforPTbr(billValue)}</Typography>;
        }
        return '';
      },
      align: 'center'
    },
    {
      field: 'buyOrder',
      headerName: 'Ordem de compra',
      maxWidth: 161,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { buyOrder } = row;
        if (buyOrder) {
          return <Typography>{`${buyOrder}`}</Typography>;
        }
        return '';
      },
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      maxWidth: 164,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ row }) => setStatus(row),
      align: 'center'
    },
    {
      field: 'ações',
      headerClassName: 'MuiDataGrid-theme--header',
      headerName: 'Ações',
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      renderCell: ({ row }) => TableOption({ options: actions, row }),
      align: 'center'
    }
  ];

  const renderDataGrid = (data) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '80vh',
        width: '100%',
        border: 'none',
        '& .MuiDataGrid-root': {
          border: 'none',
          align: 'center'
        }
      }}
    >
      <DataGridMain
        gridRows={data}
        rowCount={rowCount}
        gridColumns={columns}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        rowClassName={(params) => `active-${params.row.active}`} // Paramentro para escolha de
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        checkBox
      />
    </Box>
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      makeFilter();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [filters]);

  const makeFilter = async () => {
    const { data, count } = await billing.getBilling(filters, page, pageSize);
    setRowCount(count);
    if (data && !(JSON.stringify(data) === JSON.stringify(list))) {
      setList(data);
    }
  };

  const handleClickFilter = async () => {
    if (_.isEqual(filters, FILTERS_INITIAL_STATE)) {
      toast.warning('Preencha pelo menos um parâmetro para pesquisar');
    } else {
      await makeFilter();
    }
  };

  const cleanFilters = () => setFilters(FILTERS_INITIAL_STATE);

  const handleCleanFilters = () => cleanFilters();

  const handleReset = async () => {
    cleanFilters();
    await makeFilter();
  };

  const creatEmailObj = async () => {
    for (let count = 0; count < rowSelectionModel.length; count++) {
      // eslint-disable-next-line no-await-in-loop
      const infos = await billing.getBill(rowSelectionModel[count]);
      const { bill } = infos;
      const origins = bill.map((e) => Translate(e.origin));
      const formattedOrigins = origins
        .filter((value, index, self) => self.indexOf(value) === index)
        .join(' | ');
      const newObjEmail = {
        id: rowSelectionModel[count],
        emails: [],
        name: infos.clientName,
        type: formattedOrigins
      };
      mails.push(newObjEmail);
    }
    setObjEmail(mails);
    setSendMail(true);
  };

  const handleSendEmail = () => {
    const params = new URLSearchParams();
    rowSelectionModel.forEach((id) => {
      params.append('id', id);
    });
    navigate(`/billing/sendBillings?${params.toString()}`);
  };

  return (
    <Page title="Faturas" loading={loading}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Link to="/billing/new" style={{ textDecoration: 'none' }}>
            <Button variant="contained" endIcon={<PostAdd />} sx={{ width: 300, m: 1 }}>
              Nova Fatura
            </Button>
          </Link>
          <Button
            variant="contained"
            sx={{ width: 300, m: 1 }}
            onClick={handleSendEmail}
            disabled={rowSelectionModel.length === 0}
            endIcon={<SendIcon />}
          >
            Enviar e-mail
          </Button>
          <Paper elevation={3} sx={{ m: 1, p: 1 }}>
            <TableFilters
              clients={clients}
              clientFilterMode={clientFilterMode}
              filters={filters}
              setFilters={setFilters}
              setClientFilterMode={setClientFilterMode}
              handleCleanFilters={handleCleanFilters}
              handleClickFilter={handleClickFilter}
              handleReset={handleReset}
            />
            <TableContainer>{renderDataGrid(list, 'Enviar')}</TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <DeleteModal data={row} open={showModal} close={() => setShowModal(false)} />
      <SendMany open={sendMail} close={() => setSendMail(false)} objMail={objEmail} />
    </Page>
  );
}
