import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { passwordRegexAndCriteria, regexValidation } from '../../../utils/regex';

const PasswordCriteria = ({ password, passwordMatch }) => (
  <>
    {passwordRegexAndCriteria.map((index) => (
      <Typography
        key={index.criteria}
        style={{ display: 'flex' }}
        color={regexValidation(index.regex, password) && password !== '' ? 'inherit' : 'GrayText'}
      >
        <CheckCircleIcon style={{ marginRight: '8px' }} />
        {index.criteria}
      </Typography>
    ))}
    <Typography
      style={{ display: 'flex' }}
      color={passwordMatch() && password !== '' ? 'inherit' : 'GrayText'}
    >
      <CheckCircleIcon style={{ marginRight: '8px' }} />
      Digite sua senha novamente
    </Typography>
  </>
);

PasswordCriteria.propTypes = {
  password: PropTypes.string,
  passwordMatch: PropTypes.bool
};

export default PasswordCriteria;
