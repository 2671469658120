import PropTypes from 'prop-types';
import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ClientFilter = ({ clientFilterMode, handleChangeClientFilter, filters, clients }) => (
  <Box style={{ display: 'block', width: '500px !important' }}>
    <FormControl fullWidth>
      <>
        <InputLabel id="demo-simple-select-label">
          {clientFilterMode === 'name' ? 'Cliente' : 'CNPJ/CPF'}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={clientFilterMode === 'name' ? 'Cliente' : 'CNPJ/CPF'}
          onChange={handleChangeClientFilter}
          value={clientFilterMode === 'name' ? filters.clientName : filters.client}
          sx={{ minWidth: '300px !important' }}
        >
          {clientFilterMode === 'name'
            ? clients.map((client) => (
                <MenuItem key={client.name} value={client.name}>
                  {client.name}
                </MenuItem>
              ))
            : clients.map((client) => (
                <MenuItem key={client.document} value={client.document}>
                  {client.document}
                </MenuItem>
              ))}
        </Select>
      </>
    </FormControl>
  </Box>
);

ClientFilter.propTypes = {
  clientFilterMode: PropTypes.string,
  handleChangeClientFilter: PropTypes.func,
  filters: PropTypes.object,
  clients: PropTypes.array
};

export default ClientFilter;
