import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { Container, Stack, TextField, Typography } from '@mui/material';

import { auth } from '../../../../services';

export default function Change({ data }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [token, setToken] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirm, setConfirm] = useState(null);

  const handler = async () => {
    try {
      setError(null);

      if (password !== confirm) {
        return setError('As senhas são diferentes!');
      }

      const reset = await auth.reset({ phone: data.phone, token, password });

      if (!reset) {
        return setError('O código de segurança é inválido!');
      }

      return window.location.replace('/auth/sign-in');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ my: 2 }}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          type="number"
          label="Código SMS"
          error={!!error}
          onChange={(e) => setToken(e.target.value)}
          required
        />

        <TextField
          fullWidth
          type="password"
          label="Nova senha"
          error={!!error}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <TextField
          fullWidth
          type="password"
          label="Repita a senha"
          error={!!error}
          onChange={(e) => setConfirm(e.target.value)}
          required
        />

        {error && <Typography style={{ fontSize: 12, color: 'red' }}>{error}</Typography>}

        <LoadingButton
          fullWidth
          size="medium"
          type="submit"
          variant="contained"
          onClick={handler}
          loading={loading}
        >
          Próximo
        </LoadingButton>
      </Stack>
    </Container>
  );
}
Change.propTypes = {
  data: PropTypes.object
};
