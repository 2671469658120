import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CnpjCpfSwitch from './CnpjCpfSwitch';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

const CnpjCpfChips = ({ form, setForm }) => {
  const [cnpjCpfSwitch, setCnpjCpfSwitch] = useState(false);
  const [document, setDocument] = useState('');

  const handleChange = (event) => {
    setDocument(event.target.value);
  };

  const handleAdd = () => {
    const doc = document.replace(/[^\w\s]/gi, '');
    setForm({
      ...form,
      document: [...form.document, doc]
    });
  };

  const handleDelete = (chipToDelete) => () => {
    setForm({ ...form, document: form.document.filter((doc) => doc !== chipToDelete) });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div
        style={{
          width: '49%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <CnpjCpfSwitch cnpjCpfSwitch={cnpjCpfSwitch} setCnpjCpfSwitch={setCnpjCpfSwitch} />
        {cnpjCpfSwitch ? (
          <InputMask mask="99.999.999/9999-99" required onChange={handleChange}>
            {() => (
              <TextField
                sx={{ width: '60%' }}
                label="CNPJ"
                fullWidth
                type="text"
                size="medium"
                required
              />
            )}
          </InputMask>
        ) : (
          <InputMask mask="999.999.999-99" required onChange={handleChange}>
            {() => (
              <TextField
                sx={{ width: '60%' }}
                label="CPF"
                fullWidth
                type="text"
                size="medium"
                required
              />
            )}
          </InputMask>
        )}
        <Button sx={{ width: '15%' }} variant="outlined" onClick={handleAdd}>
          Adicionar
        </Button>
      </div>
      <div
        style={{
          width: '49%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none'
          }}
          component="ul"
        >
          {form.document.map((document) => (
            <ListItem key={document}>
              <Chip label={document} onDelete={handleDelete(document)} />
            </ListItem>
          ))}
        </Paper>
      </div>
    </div>
  );
};

CnpjCpfChips.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func.isRequired
};

export default CnpjCpfChips;
