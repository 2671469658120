import { CheckCircle, Cancel, CircleOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

PasswordCheck.propTypes = {
  label: PropTypes.string,
  isValid: PropTypes.bool
};

export function PasswordCheck({ label, isValid }) {
  const getIcon = (isValid) => {
    switch (isValid) {
      case true:
        return <CheckCircle />;
      case false:
        return <Cancel color="error" />;
      default:
        return <CircleOutlined />;
    }
  };
  return (
    <Box display="flex" paddingY=".2rem">
      {getIcon(isValid)}
      <Typography marginLeft=".5rem">{label}</Typography>
    </Box>
  );
}
