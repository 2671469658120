import { ToastContainer } from 'react-toastify';
import Router from './routes';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

import { AuthProvider } from './contexts';
import ScrollToTop from './components/ScrollToTop';

import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <ToastContainer />
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ThemeConfig>
  );
}
