import * as api from './api';

export const create = async (notify) => {
  try {
    const data = await api.post('notification', notify);
    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const findByDocument = async (document) => {
  try {
    const data = await api.get(`notification/${document}`);
    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const findLasts = async () => {
  try {
    const data = await api.get('notification/last');
    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const findAll = async () => {
  try {
    const data = await api.get('notification/');
    return data;
  } catch {
    return { statusCode: 500 };
  }
};

export const findByProtocol = async (protocol) => {
  try {
    const data = await api.get(`notification/${protocol}`);
    return data;
  } catch {
    return { statusCode: 500 };
  }
};
