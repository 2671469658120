import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import InputMask from 'react-input-mask';
import { Container, Stack, TextField } from '@mui/material';
import { removeSpecialCharactersFromPhone2 } from '../../../../utils/removeSpecialCharacters';
import { auth } from '../../../../services';

export default function Validate({ next }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState('');

  const handler = async () => {
    try {
      setError(null);
      setLoading(true);

      if (!/^((\d{2})?\s?)\d{5}(-|\s)?\d{4}$/.test(removeSpecialCharactersFromPhone2(phone))) {
        return setError('Digite um número do telefone válido');
      }

      const exist = await auth.forgot(removeSpecialCharactersFromPhone2(phone));
      if (!exist) {
        return setError('Digite um número do telefone válido');
      }

      return next({ phone: removeSpecialCharactersFromPhone2(phone) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ my: 2 }}>
      <Stack spacing={2}>
        <InputMask mask="(99) 99999-9999" onChange={(e) => setPhone(e.target.value)} value={phone}>
          {() => (
            <TextField fullWidth label="Telefone" error={!!error} helperText={error} required />
          )}
        </InputMask>

        <LoadingButton
          fullWidth
          size="medium"
          type="submit"
          variant="contained"
          onClick={handler}
          loading={loading}
        >
          Próximo
        </LoadingButton>
      </Stack>
    </Container>
  );
}

Validate.propTypes = {
  next: PropTypes.func
};
