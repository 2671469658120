import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

import { billing } from '../../../../services';

export default function DeleteModal({ data, open, close }) {
  const deleteBilling = async () => {
    await billing.deleteBilling(data.id);

    close();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deseja deletar essa fatura?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deletar a fatura do cliente {data?.clientName}, protocolo {data?.protocol}, com{' '}
          {data?.bill.length} documento{data?.bill.length === 1 || 0 ? '' : 's'}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteBilling} variant="contained" color="primary">
          Deletar
        </Button>
        <Button onClick={close} variant="outlined" color="error">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteModal.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func
};
