import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  Delete,
  Download,
  Send as SendIcon
} from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Page } from '../../../../components';
import { billing } from '../../../../services';
import { Inputs, Send } from './components';

const BillTypes = {
  rent: 'Locação',
  excess: 'Km Excedente',
  refund: 'Reembolso',
  ticket: 'Multa'
};

let toShow = 0;

export default function Visualize() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState(false);
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState({ fileURL: null, pdf: false });
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [send, setSend] = useState(false);
  const [formatDate, setFormatDate] = useState('');

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const load = async () => {
      setLoading(true);
      const doc = await billing.getBill(id);
      setData(doc);
      if (doc.lastVisualized) {
        const date = new Date(doc.lastVisualized.timestamp);
        setFormatDate(date.toLocaleString('pt-BR'));
      }
      if (doc.bill.length === 0) {
        return setLoading(false);
      }
      const { fileURL, pdf } = await billing.getDoc(doc.bill[toShow].key);

      setFiles([{ fileURL, pdf }]);
      setShow({ fileURL, pdf });

      setLoading(false);
    };
    if (files.length === 0) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const reload = () => {
    setLoading(true);
    setData(false);
    setShow({ fileURL: null, pdf: false });
    setDownload(false);
    setFiles([]);
    toShow = 0;
  };

  // eslint-disable-next-line consistent-return
  const nextDocument = async () => {
    toShow += 1;

    if (!files[toShow]) {
      setLoading(true);
      const { fileURL, pdf } = await billing.getDoc(data.bill[toShow].key);
      setFiles([...files, { fileURL, pdf }]);
      setLoading(false);
      return setShow({ fileURL, pdf });
    }

    setShow(files[toShow]);
  };

  const previousDocument = () => {
    toShow -= 1;

    setShow(files[toShow]);
  };

  const downloadDoc = async () => {
    setDownload(true);

    setTimeout(() => setDownload(false), 500);
  };

  const handleDelete = async () => {
    setLoading(true);
    const doc = await billing.update(
      data.id,
      data.bill.filter((_, i) => i !== toShow)
    );

    if (doc) {
      reload();
    }

    setLoading(false);
  };

  const seeDocuments = () => (
    <>
      <Typography variant="h5">
        Arquivo {toShow + 1} - {BillTypes[data.bill[toShow].origin]}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: '1',
          width: '100%',
          height: '100%'
        }}
      >
        <IconButton
          color="primary"
          disabled={data.bill.length === 1 || toShow === 0}
          onClick={previousDocument}
        >
          <ArrowBackIos />
        </IconButton>
        {show.pdf ? (
          <Box
            sx={{
              width: '80%',
              height: '600px',
              textAlign: 'center'
            }}
          >
            <iframe title="Documento" src={show.fileURL} height="100%" width="100%" />
          </Box>
        ) : (
          <Box
            sx={{
              width: '80%',
              height: '600px',
              textAlign: 'center'
            }}
          >
            <Typography variant="h2">Documento não visualizável</Typography>
            <Typography variant="h4">Faça o download para ver</Typography>
          </Box>
        )}
        {download && <iframe title="Documento" src={show.fileURL} height="1" width="1" />}
        <IconButton
          color="primary"
          disabled={data.bill.length === 1 || toShow === data.bill.length - 1}
          onClick={nextDocument}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          width: '100%',
          margin: 2
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={downloadDoc}
          disabled={show.pdf}
          endIcon={<Download />}
        >
          Salvar Documento
        </Button>
        <Button variant="outlined" color="error" endIcon={<Delete />} onClick={handleDelete}>
          Deletar Este Documento
        </Button>
      </Box>
    </>
  );
  return (
    <Page title="Ver Fatura" loading={loading}>
      <Box
        sx={{
          height: '90%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          borderRadius: '10px',
          padding: '10px',
          boxShadow: 24
        }}
      >
        <Box sx={{ padding: '10px', textAlign: 'center' }}>
          <Typography variant="h5">{data.clientName}</Typography>
          {data.lastVisualized ? (
            <Typography variant="p">
              Última visualização: {data.lastVisualized.name} {formatDate}
            </Typography>
          ) : (
            <Typography variant="p"> Não visualizado</Typography>
          )}
          <Typography variant="body1">
            {new Date(data.createdAt).toLocaleDateString('pt-BR', {
              timeZone: 'America/Sao_Paulo'
            })}
          </Typography>
          <Button
            variant="contained"
            color="info"
            onClick={() => setSend(true)}
            disabled={data.sent}
            endIcon={<SendIcon />}
          >
            Enviar Fatura
          </Button>
          <IconButton
            color="error"
            onClick={() => navigate('/billing')}
            size="medium"
            sx={{ position: 'fixed', top: '100px', right: '50px' }}
          >
            <Close />
          </IconButton>
        </Box>
        {loading ? null : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
              height: '80%',
              width: '90%',
              margin: '10px auto'
            }}
          >
            {!loading && data.bill.length === 0 && (
              <Typography variant="h4">
                Fatura sem documentos, adicione para poder visualizar
              </Typography>
            )}
            {!loading && data.bill.length > 0 && seeDocuments()}
            <Inputs data={data} reload={() => reload()} />
            <Send data={data} open={send} close={() => setSend(false)} />
          </Box>
        )}
      </Box>
    </Page>
  );
}
