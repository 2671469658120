import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TechnicianModal from '../TechnicianModal';
import { columns } from './columns';

export default function TechnicianSection({ technicians, users, clientId }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [usersWhitoutTechnicians, setUsersWhitoutTechnicians] = useState([]);

  useEffect(() => {
    const usersWhitoutTechnicians = users?.filter(
      (user) => !technicians?.find((technician) => technician.id === user.id)
    );
    setUsersWhitoutTechnicians(usersWhitoutTechnicians);
  }, [technicians, users]);

  const handleUnlinkTechnician = async (technicianId) => {
    try {
      window.location.reload();
      toast.success('Técnico desvinculado com sucesso!');
    } catch (error) {
      toast.error('Erro ao desvincular técnico!');
    }
  };

  return (
    <Grid item container xs={12}>
      <Box
        style={{
          width: '100%',
          display: 'block',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            marginTop: '1rem',
            fontSize: '30px'
          }}
        >
          Técnicos Vinculados
        </Typography>
        <hr
          style={{
            border: 0,
            borderTop: '2px solid #E77E22',
            marginTop: '1.3rem',
            marginBottom: '2.5rem'
          }}
        />
      </Box>
      {technicians && technicians?.length ? (
        <Grid container item xs={12}>
          <Grid container item xs={12} justifyContent="center">
            <DataGrid
              sx={{
                border: 'none',
                borderBottom: 'hidden !important',
                '& .MuiDataGrid-cell': {
                  backgroundColor: '#F2F4F7',
                  borderRadius: '8px',
                  marginLeft: '0.1rem',
                  justifyContent: 'center'
                },
                '& .MuiDataGrid-row': {
                  marginBottom: '0.1rem'
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#D0D5DD',
                  borderRadius: '8px',
                  marginLeft: '0.1rem'
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  justifyContent: 'center'
                },
                '&>.MuiDataGrid-main': {
                  '&>.MuiDataGrid-columnHeaders': {
                    borderBottom: 'none'
                  }
                }
              }}
              columns={columns(handleUnlinkTechnician)}
              rows={technicians}
              autoHeight
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              components={{
                cell: ({ value, field }) => (
                  <div style={{ borderTop: 'none', borderBottom: 'none' }}> {value[field]} </div>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} mt={5} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => navigate('/manager/clients')}
              sx={{ borderRadius: '20px', width: '100px' }}
            >
              Voltar
            </Button>
            <Button sx={{ borderRadius: '20px' }} variant="contained" onClick={() => setOpen(true)}>
              Adicionar Técnico
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={12} justifyContent="center" display="flex">
          <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
            <Typography variant="h6">Ops... não foram encontrados técnicos vinculados.</Typography>
          </Grid>
          <Button sx={{ borderRadius: '20px' }} variant="contained" onClick={() => setOpen(true)}>
            Adicionar Técnico
          </Button>
        </Grid>
      )}
      <TechnicianModal
        open={open}
        handleClose={() => setOpen(false)}
        users={usersWhitoutTechnicians}
        clientId={clientId}
      />
    </Grid>
  );
}

TechnicianSection.propTypes = {
  technicians: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  clientId: PropTypes.string
};
